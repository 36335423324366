import { Typography, Row, Col, List } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../../types/cardsType";
import Title from "antd/es/typography/Title";
const { Text } = Typography;

const Tarpaulin = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  const data = [
    "Blue",
    "Red",
    "White",
    "Black",
    "Orange",
    "Olive Green",
    "Yellow",
    "UV",
    "PPA",
    "Filler Masterbatch",
  ];
  if (content.ismobile)
    return (
      <>
        <Title level={4}>Tarpaulin</Title>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                Tarpaulins are used in many ways to protect persons and things
                from wind, rain, and sunlight. They are used during construction
                or after disasters to protect partially built or damaged
                structures, to prevent mess during painting and similar
                activities, and to contain and collect debris.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
        <Row
          key="content"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
        >
          <List
            header={<Text strong>Suggested Masterbatch:</Text>}
            // bordered
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                {"> "} <Text>{item}</Text>
              </List.Item>
            )}
            grid={{ column: 2 }}
          />
        </Row>
      </>
    );
  return (
    <>
      <Row key="content" style={{ paddingBlockStart: "20px" }}>
        <Col className={text.className}>
          <Typography>
            <Paragraph style={{ textAlign: "justify" }}>
              <Text strong>Tarpaulin: </Text>Tarpaulins are used in many ways to
              protect persons and things from wind, rain, and sunlight. They are
              used during construction or after disasters to protect partially
              built or damaged structures, to prevent mess during painting and
              similar activities, and to contain and collect debris.
            </Paragraph>
          </Typography>
        </Col>
      </Row>
      <Row
        className={content.className}
        gutter={content.gutter}
        justify="space-between"
        align="middle"
      >
        <Col span={12}>
          <Row>
            <Col>
              <List
                header={<Text strong>Suggested Masterbatch:</Text>}
                // bordered
                dataSource={data}
                renderItem={(item) => (
                  <List.Item>
                    {"> "} <Text>{item}</Text>
                  </List.Item>
                )}
                grid={{ column: 1, gutter: 2 }}
              />
            </Col>
          </Row>
        </Col>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="100%"
            alt="img"
            loading="lazy"
          />
        </Col>
      </Row>
    </>
  );
};

export default Tarpaulin;
