export const HomeMapDataSource = {
  wrapper: { className: "home-page-wrapper content10-wrapper" },
  Content: {
    className: "icon-wrapper",
    children: {
      icon: {
        className: "icon",
        children:
          "https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg",
        name: "Main Icon",
      },
      iconShadow: {
        className: "icon-shadow",
        children:
          "https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg",
        
        name: "Icon Shadow",
      },
      url: {
        children: "https://maps.app.goo.gl/wRPGQd52Le59KfSAA",
        name: "Jump Address",
      },
      title: { children: "Navkar Polymers", name: "Popup Title" },
      content: {
        children:
          "76, Shree Krishna Industrial Estate, Near Radheyshyam Industrial Estate, S.P Ring Road, Odhav, Ahmedabad - 382415",
        name: "Popup Content",
      },
    },
  },
};
