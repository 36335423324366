import React from "react";
import { Button, Form, Input, InputNumber, Select } from "antd";
import { countryCodes } from "../data-source/CountryCodeList";
import axios from "axios";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: "${label} is required!",
  number: {
    // eslint-disable-next-line no-template-curly-in-string
    range: "${label} must be between ${min} and ${max}",
  },
};

const onFinish = (values: any) => {
  console.log("form values" + JSON.stringify(values));
  // Send form values to the backend
  axios
    .post("https://navkarpolymers.com/api/submit-form", values)
    .then((response) => {
      console.log("Email sent successfully:", response);
    })
    .catch((error) => {
      console.error("There was an error sending the email:", error);
    });
};
const prefixSelector = (
  <Form.Item name="prefix" noStyle initialValue="India +91">
    <Select options={countryCodes} />
  </Form.Item>
);

const ContactUsFormPage: React.FC = () => (
  <Form
    {...layout}
    className="form-wrapper"
    onFinish={onFinish}
    labelAlign="left"
    requiredMark={false}
    style={{ maxWidth: 600 }}
    validateMessages={validateMessages}
  >
    <Form.Item
      name={["user", "name"]}
      label="Name"
      className="form-content"
      rules={[{ required: true }]}
    >
      <Input />
    </Form.Item>
    <Form.Item name={["user", "email"]} label="Email">
      <Input />
    </Form.Item>
    <Form.Item
      name={["user", "phone"]}
      label="Phone Number"
      rules={[{ type: "number", required: true }]}
    >
      <InputNumber addonBefore={prefixSelector} style={{ width: "100%" }} />
    </Form.Item>
    <Form.Item
      name={["user", "company"]}
      label="Company"
      rules={[{ required: true }]}
    >
      <Input />
    </Form.Item>
    <Form.Item style={{ width: "100%" }}>
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
);

export default ContactUsFormPage;
