import HomePageBanner from "./HomePageBanner";
import ProductsAndServices from "./ProductsAndServices";
import "./less/antMotionStyle.less";
import { HomeState } from "../types/HomeState";

import MarketingCards from "./MarketingCards";
import Testimonials from "./Testimonials";
import Clients from "./Clients";
import ProductsCards from "../Products/ProductsCards";

export default function HomePage({ ismobile }: HomeState) {
  return (
    <>
      <HomePageBanner ismobile={ismobile} />
      <ProductsAndServices ismobile={ismobile} />
      <ProductsCards ismobile={ismobile} />
      {/* <HomeAboutUs ismobile={ismobile} /> */}
      <MarketingCards ismobile={ismobile} />
      <Testimonials ismobile={ismobile} />
      <Clients ismobile={ismobile} />
    </>
  );
}
