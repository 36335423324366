import { Col, Row, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../types/cardsType";

const Textile = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  if (content.ismobile)
    return (
      <>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                Plastic masterbatch plays a crucial role in the textile
                industry, offering a range of benefits that enhance the
                functionality, appearance, and sustainability of textile
                products. In this sector, masterbatch is primarily used to
                incorporate color, additives, and performance-enhancing
                properties into synthetic fibers and fabrics.
              </Paragraph>
              <Paragraph style={{ textAlign: "justify" }}>
                One of the most common applications of plastic masterbatch in
                textiles is coloration. By adding masterbatch pigments and dyes
                to the polymer matrix during the fiber or fabric production
                process, manufacturers can achieve precise and consistent
                coloration. This process eliminates the need for post-dyeing
                treatments, reducing water and energy consumption, and
                minimizing environmental impact. The ability to customize colors
                with masterbatch enables textile designers to meet the specific
                aesthetic requirements of various markets, from fashion and
                apparel to home textiles and automotive interiors.
              </Paragraph>
              <Paragraph style={{ textAlign: "justify" }}>
                Beyond coloration, plastic masterbatch is utilized to improve
                the performance characteristics of textile materials. For
                instance, antimicrobial and UV stabilizer additives in
                masterbatch can enhance the durability and lifespan of outdoor
                textiles, such as awnings and upholstery, by protecting them
                from degradation caused by UV radiation and microbial growth.
                Flame retardant additives are crucial for textiles used in
                applications where fire safety is a concern, such as curtains,
                upholstery, and industrial protective gear. These additives
                ensure that textiles meet stringent safety standards without
                compromising their comfort or appearance.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
      </>
    );
  return (
    <Row key="content" className={content.className} gutter={content.gutter}>
      <Col className={text.className} xs={text.xs} md={text.md}>
        <Typography>
          <Paragraph style={{ textAlign: "justify" }}>
            Plastic masterbatch plays a crucial role in the textile industry,
            offering a range of benefits that enhance the functionality,
            appearance, and sustainability of textile products. In this sector,
            masterbatch is primarily used to incorporate color, additives, and
            performance-enhancing properties into synthetic fibers and fabrics.
          </Paragraph>
          <Paragraph style={{ textAlign: "justify" }}>
            One of the most common applications of plastic masterbatch in
            textiles is coloration. By adding masterbatch pigments and dyes to
            the polymer matrix during the fiber or fabric production process,
            manufacturers can achieve precise and consistent coloration. This
            process eliminates the need for post-dyeing treatments, reducing
            water and energy consumption, and minimizing environmental impact.
            The ability to customize colors with masterbatch enables textile
            designers to meet the specific aesthetic requirements of various
            markets, from fashion and apparel to home textiles and automotive
            interiors.
          </Paragraph>
          <Paragraph style={{ textAlign: "justify" }}>
            Beyond coloration, plastic masterbatch is utilized to improve the
            performance characteristics of textile materials. For instance,
            antimicrobial and UV stabilizer additives in masterbatch can enhance
            the durability and lifespan of outdoor textiles, such as awnings and
            upholstery, by protecting them from degradation caused by UV
            radiation and microbial growth. Flame retardant additives are
            crucial for textiles used in applications where fire safety is a
            concern, such as curtains, upholstery, and industrial protective
            gear. These additives ensure that textiles meet stringent safety
            standards without compromising their comfort or appearance.
          </Paragraph>
        </Typography>
      </Col>
      <Col className={img.className} xs={img.xs} md={img.md}>
        <img src={content.img.children} width="100%" alt="img" loading="lazy" />
      </Col>
    </Row>
  );
};

export default Textile;
