import { useParams } from "react-router-dom";
import { HomeState } from "../types/HomeState";

import ProductsCards from "./ProductsCards";
import LoadingScreen from "../LoadingPage";
import { cacheImages } from "../LoadingPage/imagePreloadUtil";
import { useEffect, useState } from "react";

import "./less/antMotionStyle.less";

export default function Products({ ismobile }: HomeState) {
  const id = useParams()["*"];
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    cacheImages(
      [
        "/images/products/additivemasterbatch.webp",
        "/images/products/blackmasterbatch.webp",
        "/images/products/colourmasterbatch.webp",
        "/images/products/petmasterbatch.webp",
        "/images/products/whitemasterbatch.webp",
        "/images/products/addititivemasterbatch/Anti block.webp",
        "/images/products/addititivemasterbatch/Anti micobial.webp",
        "/images/products/addititivemasterbatch/anti-fog-peaches-in-plastic-packaging.webp",
        "/images/products/addititivemasterbatch/Corrosion limited.webp",
        "/images/products/addititivemasterbatch/Flame retardant.webp",
        "/images/products/addititivemasterbatch/Opitical Brightner.webp",
        "/images/products/addititivemasterbatch/polymer-processing-aid-masterbatch-2590733.webp",
        "/images/products/addititivemasterbatch/slip masterbatch.webp",
        "/images/products/addititivemasterbatch/static.webp",
        "/images/products/addititivemasterbatch/UV-Masterbatch.webp",
      ],
      setIsLoading
    );
  }, []);
  if (isLoading) return <LoadingScreen ismobile={ismobile} />;

  return <ProductsCards ismobile={ismobile} childname={id} />;
}
