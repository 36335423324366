import { Typography, List, Col, Row } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../types/cardsType";
import Title from "antd/es/typography/Title";
const { Text } = Typography;
const BlackMasterBatch = (content: ChildPageContentConfig) => {
  const benefits = [
    "Duarability",
    "Jetness",
    "Self UV Resistant",
    "No issue of colour bleed",
    "Uniform dispersion",
  ];
  const applications = [
    "Tarpaulin",
    "Ground Covers",
    "Non Woven fabrics",
    "Mulch Film",
    "Lamination",
    "Drip Pipes",
    "HDPE Pipes",
    "Blown Film",
    "Wovensacks",
  ];

  const { text, img } = content;
  if (content.ismobile)
    return (
      <>
        <Title level={4}>Black Masterbatch</Title>

        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="100%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content"
          className={content.className}
          gutter={content.gutter}
        ></Row>
        <Row key="content" style={{ paddingBlockStart: "20px" }}>
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                Black Masterbatch contains carbon black, polymers and other
                dispersing agents. We manufacture black masterbatch ranging from
                20 to 50% carbon black loading as per requirements of clients.
                We use good quality raw material and dispersing agents helps to
                achieve high quality results.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
        <Row
          className={content.className}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Row>
            <Col>
              <List
                header={<Text strong>Applications:</Text>}
                dataSource={applications}
                renderItem={(item) => (
                  <List.Item>
                    {"> "} <Text>{item}</Text>
                  </List.Item>
                )}
                grid={{ column: 2 }}
              />
            </Col>
            <Col>
              <List
                header={<Text strong>Benefits of Black Masterbatch:</Text>}
                dataSource={benefits}
                renderItem={(item) => <List.Item>{"> " + item}</List.Item>}
                grid={{ column: 1, gutter: 2 }}
              />
            </Col>
          </Row>
        </Row>
      </>
    );
  return (
    <>
      <Row key="content" style={{ paddingBlockStart: "20px" }}>
        <Col className={text.className}>
          <Typography>
            <Paragraph>
              <Text strong>Black Masterbatch: </Text> Black Masterbatch contains
              carbon black, polymers and other dispersing agents. We manufacture
              black masterbatch ranging from 20 to 50% carbon black loading as
              per requirements of clients. We use good quality raw material and
              dispersing agents helps to achieve high quality results.
            </Paragraph>
          </Typography>
        </Col>
      </Row>
      <Row
        className={content.className}
        gutter={content.gutter}
        justify="space-between"
        align="middle"
      >
        <Col span={12}>
          <Row>
            <Col>
              <List
                header={<Text strong>Applications:</Text>}
                dataSource={applications}
                renderItem={(item) => (
                  <List.Item>
                    {"> "} <Text>{item}</Text>
                  </List.Item>
                )}
                grid={{ column: 2 }}
              />
            </Col>
            <Col>
              <List
                header={<Text strong>Benefits of Black Masterbatch:</Text>}
                dataSource={benefits}
                renderItem={(item) => <List.Item>{"> " + item}</List.Item>}
                grid={{ column: 1, gutter: 2 }}
              />
            </Col>
          </Row>
        </Col>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="100%"
            alt="img"
            loading="lazy"
          />
        </Col>
      </Row>
    </>
  );
};

export default BlackMasterBatch;
