import { CardsType, ChildPageContentConfig } from "../types/cardsType";
import AdditiveMasterBatch from "./products/additivemasterbatch";
import BlackMasterBatch from "./products/blackmasterbatch";
import ColourMasterBatch from "./products/colourmasterbatch";
import WhiteMasterBatch from "./products/whitemasterbatch";
import CorrosionInhabitors from "./products/additivemasterbatch/CorrosionInhabitors";
import AntiMicrobials from "./products/additivemasterbatch/AntiMicrobials";
import AntiFog from "./products/additivemasterbatch/AntiFog";
import AntiBlock from "./products/additivemasterbatch/AntiBlock";
import Slip from "./products/additivemasterbatch/Slip";
import PPA from "./products/additivemasterbatch/PPA";
import OpticalBrightner from "./products/additivemasterbatch/OpticalBrightner";
import Antistatic from "./products/additivemasterbatch/Antistatic";
import FlameRetardant from "./products/additivemasterbatch/FlameRetardant";
import UV from "./products/additivemasterbatch/UV";

export const ProductsData: CardsType[] = [
  {
    imgSrc: "/images/products/blackmasterbatch.webp",
    title: "Black Masterbatches",
    link: "/products/blackmasterbatch",
    component: (props: ChildPageContentConfig) => (
      <BlackMasterBatch {...props} />
    ),
  },
  {
    imgSrc: "/images/products/whitemasterbatch.webp",
    title: "White Masterbatches",
    link: "/products/whitemasterbatch",
    component: (props: ChildPageContentConfig) => (
      <WhiteMasterBatch {...props} />
    ),
  },
  {
    imgSrc: "/images/products/colourmasterbatch.webp",
    title: "Colour Masterbatches",
    link: "/products/colourmasterbatch",
    component: (props: ChildPageContentConfig) => (
      <ColourMasterBatch {...props} />
    ),
  },
  {
    imgSrc: "/images/products/additivemasterbatch.webp",
    title: "Additive Masterbatches",
    link: "/products/additivemasterbatch",
    component: (props: ChildPageContentConfig) => (
      <AdditiveMasterBatch {...props} />
    ),
    children: [
      {
        imgSrc: "/images/products/addititivemasterbatch/UV-Masterbatch.webp",
        title: "UV",
        link: "/products/additivemasterbatch/uv",
        component: (props: ChildPageContentConfig) => <UV {...props} />,
      },
      {
        imgSrc: "/images/products/addititivemasterbatch/Flame retardant.webp",
        title: "Flame Retardant",
        link: "/products/additivemasterbatch/flameretardant",
        component: (props: ChildPageContentConfig) => (
          <FlameRetardant {...props} />
        ),
      },
      {
        imgSrc: "/images/products/addititivemasterbatch/static.webp",
        title: "Antistatic",
        link: "/products/additivemasterbatch/antistatic",
        component: (props: ChildPageContentConfig) => <Antistatic {...props} />,
      },
      {
        imgSrc:
          "/images/products/addititivemasterbatch/Opitical Brightner.webp",
        title: "Optical Brightner",
        link: "/products/additivemasterbatch/opticalbrightner",
        component: (props: ChildPageContentConfig) => (
          <OpticalBrightner {...props} />
        ),
      },
      {
        imgSrc:
          "/images/products/addititivemasterbatch/polymer-processing-aid-masterbatch-2590733.webp",
        title: "PPA",
        link: "/products/additivemasterbatch/ppa",
        component: (props: ChildPageContentConfig) => <PPA {...props} />,
      },
      {
        imgSrc: "/images/products/addititivemasterbatch/slip masterbatch.webp",
        title: "Slip",
        link: "/products/additivemasterbatch/slip",
        component: (props: ChildPageContentConfig) => <Slip {...props} />,
      },
      {
        imgSrc: "/images/products/addititivemasterbatch/Anti block.webp",
        title: "Anti Block",
        link: "/products/additivemasterbatch/antiblock",
        component: (props: ChildPageContentConfig) => <AntiBlock {...props} />,
      },
      {
        imgSrc:
          "/images/products/addititivemasterbatch/anti-fog-peaches-in-plastic-packaging.webp",
        title: "Anti Fog",
        link: "/products/additivemasterbatch/antifog",
        component: (props: ChildPageContentConfig) => <AntiFog {...props} />,
      },
      {
        imgSrc: "/images/products/addititivemasterbatch/Anti micobial.webp",
        title: "Anti Microbials",
        link: "/products/additivemasterbatch/antimicrobials",
        component: (props: ChildPageContentConfig) => (
          <AntiMicrobials {...props} />
        ),
      },
      {
        imgSrc: "/images/products/addititivemasterbatch/Corrosion limited.webp",
        title: "Corrosion Inhabitors",
        link: "/products/additivemasterbatch/corrosioninhabitors",
        component: (props: ChildPageContentConfig) => (
          <CorrosionInhabitors {...props} />
        ),
      },
    ],
  },
];

export const ProductsCardsDataSource = {
  wrapper: { className: "products-wrapper content5-wrapper" },
  page: { className: "products content5" },
  OverPack: { playScale: 0.3, className: "products-overpack-block" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      { name: "title", children: "Products", className: "title-h1" },
      {
        name: "content",
        className: "title-content",
        children: "Products we serve",
      },
    ],
  },
  block: {
    className: "content5-img-wrapper",
    gutter: 16,
    children: ProductsData.map((data, index) => {
      return {
        name: "block" + index,
        className: "block",
        link: data.link,
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children: data.imgSrc,
          },
          content: { children: data.title },
        },
      };
    }),
  },
};
