import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import BannerAnim, { Element } from "rc-banner-anim";
import TweenOne from "rc-tween-one";
import QueueAnim from "rc-queue-anim";
import { getChildrenToRender } from "./utils";
import "rc-banner-anim/assets/index.css";
import { TestimonialsDataSource } from "../data-source/TestimonialsDataSource";
import { HomeState } from "../types/HomeState";

const dataSource = TestimonialsDataSource;
const Testimonials = (props: HomeState) => {
  const getChildrenToRenderIn = (children: any[]) => {
    return children.map((item, i) => {
      const { titleWrapper, ...elementPros } = item;
      return (
        <Element
          {...elementPros}
          key={i.toString()}
          prefixCls={elementPros.className}
        >
          <QueueAnim
            type={["bottom", "top"]}
            delay={200}
            key="text"
            {...titleWrapper}
          >
            {titleWrapper.children.map(getChildrenToRender)}
          </QueueAnim>
        </Element>
      );
    });
  };

  return (
    <div {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack}>
        <TweenOne
          key="wrapper"
          animation={
            props.ismobile
              ? {
                  scaleY: "+=0.3",
                  opacity: 0,
                  type: "from",
                  ease: "easeOutQuad",
                }
              : {
                  y: "+=30",
                  opacity: 0,
                  type: "from",
                  ease: "easeOutQuad",
                }
          }
          resetStyle
          component=""
        >
          <BannerAnim
            type="across"
            key="BannerAnim2"
            thumb={false}
            arrow={false}
            dragPlay={!!props.ismobile}
            {...dataSource.BannerAnim}
            autoPlay
            autoPlaySpeed={5000}
          >
            {getChildrenToRenderIn(dataSource.BannerAnim.children)}
          </BannerAnim>
        </TweenOne>
      </OverPack>
    </div>
  );
};

export default Testimonials;
