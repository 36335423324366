import { MarketingData } from "./MarketingCardsDataSource";
import { ProductsData } from "./ProductsCardsDataSource";
export type NavigationBarDataType = {
  page: {
    className: string;
  };
  Menu: {
    children: NavItem[];
  };
  mobileMenu: {
    className: string;
  };
};
export type NavItem = {
  name: string;
  subMenu?: NavItem[];
  a: {
    children: string;
    href: string;
    target?: string;
  };
};
export const NavigationBarData: NavigationBarDataType = {
  page: { className: "home-page" },
  Menu: {
    children: [
      { name: "About us", a: { children: "About us", href: "/aboutus" } },
      {
        name: "Products",
        a: { children: "Products", href: "/products" },
        subMenu: ProductsData.map((e) => {
          return {
            name: e.title || "",
            a: { children: e.title || "", href: e.link || "" },
          };
        }),
      },
      {
        name: "Market",
        a: {
          children: "Market",
          href: "/market",
        },
        subMenu: MarketingData.filter((e) => !!e?.title).map((e) => {
          return {
            name: e?.title || "",
            a: { children: e?.title || "", href: e?.link || "" },
          };
        }),
      },
      { name: "Contact us", a: { children: "Contact us", href: "/contactus" } },
    ],
  },
  mobileMenu: { className: "header0-mobile-menu" },
};
