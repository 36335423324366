export const CompanyProfileDataSource = {
  OverPack: {
    className: "contact-us-wrapper content13-contactus-wrapper",
    playScale: 0.3,
  },
  titleWrapper: {
    className: "title-wrapper",
    children: [],
  },
};
