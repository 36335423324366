import React from "react";

export const cacheImages = async (
  srcArray: string[],
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading(true);

  const promises = srcArray.map((url) => {
    const img = new Image();
    img.src = url;
    return new Promise<void>((resolve, reject) => {
      img.onload = () => {
        resolve();
      };
      img.onerror = (e) => {
        console.error("Error loading image", img.src, e);
        reject(e);
      };
    });
  });

  try {
    await Promise.all(promises);
  } catch (error) {
    console.error("Error loading one or more images", error);
  } finally {
    setIsLoading(false);
  }
};
