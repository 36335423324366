import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { FooterDataSource } from "../data-source/FooterDataSource";
import { HomeState } from "../types/HomeState";
import QueueAnim from "rc-queue-anim";
import { Col, Row, Space } from "antd";
import { isImg } from "./utils";
import TweenOne from "rc-tween-one";
import { GrMapLocation } from "react-icons/gr";
import { IoCallOutline } from "react-icons/io5";
import { AiOutlineWhatsApp, AiOutlineMail } from "react-icons/ai";
import ContactUsForm from "./ContactUsForm";

const dataSource = FooterDataSource;

const Footer = (props: HomeState) => {
  const renderChildren = (data: any, idx: number) => {
    if (data.type === "address") {
      return (
        <Space key={idx} style={props.ismobile ? { width: "90%" } : {}}>
          <GrMapLocation />
          <div onClick={() => window.open(data.action, "_blank", "noreferrer")}>
            {data.children}
          </div>
        </Space>
      );
    } else if (data.type === "call") {
      return (
        <Space key={idx}>
          <IoCallOutline />
          <div onClick={() => window.open(data.action, "_blank", "noreferrer")}>
            {data.children}
          </div>
        </Space>
      );
    } else if (data.type === "whatsapp") {
      return (
        <Space key={idx}>
          <AiOutlineWhatsApp />
          <div onClick={() => window.open(data.action, "_blank", "noreferrer")}>
            {data.children}
          </div>
        </Space>
      );
    } else if (data.type === "email") {
      return (
        <Space key={idx}>
          <AiOutlineMail />
          <div onClick={() => window.open(data.action, "_blank", "noreferrer")}>
            {data.children}
          </div>
        </Space>
      );
    }
  };
  const getLiChildren = (data: any[]) => {
    const output = data.map((item, i) => {
      const { title, childWrapper, ...itemProps } = item;
      return (
        <Col key={i.toString()} {...itemProps} title={null} content={null}>
          <h2 {...title}>
            {typeof title.children === "string" &&
            (title.children.match(isImg) ||
              title.children.startsWith("/images")) ? (
              <img src={title.children} width="100%" alt="img" loading="lazy" />
            ) : (
              title.children
            )}
          </h2>
          <div {...childWrapper}>
            <Space direction="vertical">
              {childWrapper.children.map(renderChildren)}
            </Space>
          </div>
        </Col>
      );
    });
    output.push(
      <Col key="contactusform" xs={24} md={12}>
        <ContactUsForm ismobile={props.ismobile} />
      </Col>
    );
    return output;
  };

  const childrenToRender = getLiChildren(dataSource.block.children);
  return (
    <div {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack}>
        <QueueAnim
          type="bottom"
          key="ul"
          leaveReverse
          //@ts-ignore
          component={Row}
          {...dataSource.block}
        >
          {childrenToRender}
        </QueueAnim>
        <TweenOne
          animation={{ y: "+=30", opacity: 0, type: "from" }}
          key="copyright"
          {...dataSource.copyrightWrapper}
        >
          <div {...dataSource.copyrightPage}>
            <div {...dataSource.copyright}>{dataSource.copyright.children}</div>
          </div>
        </TweenOne>
      </OverPack>
    </div>
  );
};

export default Footer;
