import { useEffect, useState } from "react";
import TweenOne from "rc-tween-one";
import { Drawer, Menu } from "antd";
import { Link } from "react-router-dom";
import {
  NavItem,
  NavigationBarData,
  NavigationBarDataType,
} from "../data-source/NavigationBarData";
import { MenuProps } from "antd/lib/menu/menu";
import { HomeState } from "../types/HomeState";
type MenuItem = Required<MenuProps>["items"][number];

const NavBar = (props: HomeState) => {
  let menu: HTMLElement | null = null;

  const [phoneOpen, setPhoneOpen] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    setMenuHeight(0);
    setPhoneOpen(false);
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const phoneClick = () => {
    if (!menu) return; // Check if menu exists
    setPhoneOpen(true);
    phoneOpen ? setMenuHeight(0) : setMenuHeight(menu.scrollHeight);
  };

  const dataSource: NavigationBarDataType = NavigationBarData;
  const navData: NavItem[] = dataSource.Menu.children;
  const navChildren: MenuProps["items"] = navData.map((item, i) => {
    return {
      key: i.toString(),
      label: (
        <Link {...item.a} to={item.a.href} target={item.a.target}>
          {item.a.children}
        </Link>
      ),
      children: item?.subMenu?.map((subMenu, idx) => {
        return {
          key: i.toString() + "-" + idx.toString(),
          ...subMenu,
          label: (
            <Link {...subMenu.a} to={subMenu.a.href} target={subMenu.a.target}>
              {subMenu.a.children}
            </Link>
          ),
        };
      }),
    } as MenuItem;
  });

  const getMenuComponent = (
    <Menu
      mode={props.ismobile ? "inline" : "horizontal"}
      defaultSelectedKeys={[]}
      theme={"light"}
      items={navChildren}
      disabledOverflow
      onClick={() => setPhoneOpen(false)}
    ></Menu>
  );
  return (
    <TweenOne
      component="header"
      animation={{ opacity: 0, type: "from" }}
      className={
        scrolled
          ? "header0 home-page-wrapper gradient scrolled"
          : "header0 home-page-wrapper gradient"
      }
    >
      <div
        {...dataSource.page}
        className={`${dataSource.page.className}${phoneOpen ? " open" : ""}`}
      >
        <TweenOne
          animation={{ x: -30, type: "from", ease: "easeOutQuad" }}
          className={scrolled ? "header0-logo scrolled" : "header0-logo"}
        >
          <Link to="/">
            {scrolled ? (
              <img
                id="navbar-logo-scrolled"
                className="scrolled"
                width="100%"
                src={
                  props.ismobile
                    ? "/images/navkar-logo-mobile-scrolled.webp"
                    : "/images/logo.webp"
                }
                alt="img"
              />
            ) : (
              <img
                id="navbar-logo"
                width="100%"
                src={"/images/navbar logo.webp"}
                alt="img"
              />
            )}
          </Link>
        </TweenOne>
        {props.ismobile && (
          <div
            {...dataSource.mobileMenu}
            onClick={() => {
              phoneClick();
            }}
          >
            <em className={scrolled ? "em-scrolled" : "em-unscrolled"} />
            <em className={scrolled ? "em-scrolled" : "em-unscrolled"} />
            <em className={scrolled ? "em-scrolled" : "em-unscrolled"} />
          </div>
        )}
        <TweenOne
          {...dataSource.Menu}
          className={scrolled ? "header0-menu scrolled" : "header0-menu"}
          animation={{ x: 30, type: "from", ease: "easeOutQuad" }}
          ref={(c: HTMLElement | null) => {
            menu = c;
          }}
          style={props.ismobile ? { height: menuHeight } : {}}
        >
          {props.ismobile ? (
            <Drawer
              closable
              open={phoneOpen}
              onClose={() => setPhoneOpen(false)}
              className="header0"
            >
              {getMenuComponent}
            </Drawer>
          ) : (
            getMenuComponent
          )}
        </TweenOne>
      </div>
    </TweenOne>
  );
};

export default NavBar;
