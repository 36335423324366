import { DownOutlined } from "@ant-design/icons";
import TweenOne from "rc-tween-one";
import { HomeState } from "../types/HomeState";
import { CompanyLogoBannerDataSource } from "../data-source/CompanyLogoBannerDataSource";

const CompanyBannerLogo = (props: HomeState) => {
  const dataSource = CompanyLogoBannerDataSource;
  return (
    <div {...dataSource.wrapper}>
      {!props.ismobile && (
        <TweenOne
          animation={{
            y: "-=20",
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="banner0-icon"
          key="icon"
        >
          <DownOutlined />
        </TweenOne>
      )}
    </div>
  );
};
export default CompanyBannerLogo;
