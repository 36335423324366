import { HomeState } from "../types/HomeState";
import Footer from "./Footer";
import "./less/antMotionStyle.less";

export default function Home({ ismobile }: HomeState) {
  return (
    <>
      <Footer ismobile={ismobile} />
    </>
  );
}
