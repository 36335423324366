import { getChildrenToRender } from "../Home/utils";
import { ContactUsFormDataSource } from "../data-source/ContactUsFormDataSource";
import { HomeState } from "../types/HomeState";
import ContactUsFormPage from "./ContactUsFormPage";
const dataSource = ContactUsFormDataSource;
const ContactUsForm = (props: HomeState) => {
  return (
    <div {...dataSource.OverPack} {...dataSource.titleWrapper}>
      {dataSource.titleWrapper.children.map(getChildrenToRender)}
      <ContactUsFormPage />
    </div>
  );
};

export default ContactUsForm;
