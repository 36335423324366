import React, { useState } from "react";
import QueueAnim from "rc-queue-anim";
import { HomeMapDataSource } from "../data-source/HomeMapDataSource";
import { HomeState } from "../types/HomeState";
const dataSource = HomeMapDataSource;

const HomeMap = (props: HomeState) => {
  const [showInfo, setShowInfo] = useState(props.ismobile || false);

  const onClick = () => {
    window.open(dataSource.Content.children.url.children);
  };

  const markerEnter = () => {
    setShowInfo(true);
  };

  const markerLeave = () => {
    setShowInfo(false);
  };

  return (
    <div {...dataSource.wrapper}>
      <div
        {...dataSource.Content}
        onMouseEnter={markerEnter}
        onMouseLeave={markerLeave}
        onClick={onClick}
        onTouchEnd={onClick}
      >
        <div {...dataSource.Content.children.icon}>
          <img
            src={dataSource.Content.children.icon.children}
            alt="img"
            loading="lazy"
          />
        </div>
        <div {...dataSource.Content.children.iconShadow}>
          <img
            style={{ display: "none" }}
            src={dataSource.Content.children.iconShadow.children}
            alt="img"
          />
        </div>
      </div>
      <div>
        {showInfo && (
          <QueueAnim key="homemap" type="scale">
            <div className="map-tip" key="map">
              <h2>{dataSource.Content.children.title.children}</h2>
              <p>{dataSource.Content.children.content.children}</p>
            </div>
          </QueueAnim>
        )}
      </div>
    </div>
  );
};

export default HomeMap;
