import { CardsType, ChildPageContentConfig } from "../types/cardsType";
import Agriculture from "./marketing/agriculture";
import DripIrrigation from "./marketing/agriculture/dripirrigation";
import MulchFilm from "./marketing/agriculture/mulchfilm";
import PondLining from "./marketing/agriculture/pondlining";
import ShadeNet from "./marketing/agriculture/shadenet";
import Automotive from "./marketing/automotive";
import DuctPipe from "./marketing/infrastructure/ductpipe";
import HDPEPipe from "./marketing/infrastructure/hdpepipe";
import WaterTanks from "./marketing/infrastructure/watertanks";
import BlownFilm from "./marketing/others/blownfilm";
import Moulding from "./marketing/others/moulding";
import PPSheet from "./marketing/others/ppsheet";
import Toys from "./marketing/others/toys";
import Packing from "./marketing/packing";
import NonWovenFabrics from "./marketing/technicaltextiles/nonwovenfabrics";
import Roofing from "./marketing/technicaltextiles/roofing";
import WeedMat from "./marketing/technicaltextiles/weedmat";
import Textile from "./marketing/textile";
import MonofilamentYarns from "./marketing/textiles/monofilamentyarns";
import MultifilamentYarns from "./marketing/textiles/multifilamentyarns";
import CementBags from "./marketing/wovensack/cementbags";
import LenoBags from "./marketing/wovensack/lenobags";
import Tarpaulin from "./marketing/wovensack/tarpaulin";

export const MarketingData: CardsType[] = [
  {
    imgSrc: "/images/market/agriculture.webp",
    title: "Agriculture",
    link: "/market/agriculture",
    component: (props: ChildPageContentConfig) => <Agriculture {...props} />,
    children: [
      {
        imgSrc: "/images/market/agriculture/Mulch ilm.webp",
        title: "Mulch Film",
        link: "/market/agriculture/mulchfilm",
        component: (props: ChildPageContentConfig) => <MulchFilm {...props} />,
      },
      {
        imgSrc: "/images/market/agriculture/Drip.webp",
        title: "Drip Irrigation",
        link: "/market/agriculture/dripirrigation",
        component: (props: ChildPageContentConfig) => (
          <DripIrrigation {...props} />
        ),
      },
      {
        imgSrc: "/images/market/agriculture/pond.webp",
        title: "Pond Lining",
        link: "/market/agriculture/pondlining",
        component: (props: ChildPageContentConfig) => <PondLining {...props} />,
      },
      {
        imgSrc: "/images/market/agriculture/Green Shade net 2.webp",
        title: "Shade Net",
        link: "/market/agriculture/shadenet",
        component: (props: ChildPageContentConfig) => <ShadeNet {...props} />,
      },
    ],
  },
  {
    imgSrc: "/images/market/textileandfibres.webp",
    title: "Fibres and Textiles",
    link: "/market/textile",
    component: (props: ChildPageContentConfig) => <Textile {...props} />,
    children: [
      {
        imgSrc: "/images/market/textileandfibre/pp-multifilament-yarn-lg.webp",
        title: "Multifilament Yarns",
        link: "/market/textile/multifilamentyarns",
        component: (props: ChildPageContentConfig) => (
          <MultifilamentYarns {...props} />
        ),
      },
      {
        imgSrc: "/images/market/textileandfibre/monofilament.webp",
        title: "Monofilament Yarns",
        link: "/market/textile/monofilamentyarns",
        component: (props: ChildPageContentConfig) => (
          <MonofilamentYarns {...props} />
        ),
      },
    ],
  },
  {
    imgSrc: "/images/market/wovensack.webp",
    title: "Woven Sack",
    link: "/market/wovensack",
    component: (props: ChildPageContentConfig) => <Automotive {...props} />,
    children: [
      {
        imgSrc: "/images/market/wovensack/Tarpaulin.webp",
        title: "Tarpaulin",
        link: "/market/wovensack/tarpaulin",
        component: (props: ChildPageContentConfig) => <Tarpaulin {...props} />,
      },
      {
        imgSrc: "/images/market/wovensack/cement bags.webp",
        title: "Cement bags",
        link: "/market/wovensack/cementbags",
        component: (props: ChildPageContentConfig) => <CementBags {...props} />,
      },
      {
        imgSrc: "/images/market/wovensack/leno-bags.webp",
        title: "Leno bags",
        link: "/market/wovensack/lenobags",
        component: (props: ChildPageContentConfig) => <LenoBags {...props} />,
      },
    ],
  },
  {
    imgSrc: "/images/market/Infra.webp",
    title: "Infrastructure",
    link: "/market/infrastructure",
    component: (props: ChildPageContentConfig) => <Packing {...props} />,
    children: [
      {
        imgSrc: "/images/market/infra/plb-duct-pipes.webp",
        title: "Duct Pipe",
        link: "/market/infrastructure/ductpipe",
        component: (props: ChildPageContentConfig) => <DuctPipe {...props} />,
      },
      {
        imgSrc: "/images/market/infra/HDPE Pipe.webp",
        title: "HDPE Pipe",
        link: "/market/infrastructure/hdpepipe",
        component: (props: ChildPageContentConfig) => <HDPEPipe {...props} />,
      },
      {
        imgSrc: "/images/market/infra/water tanks.webp",
        title: "Water tanks",
        link: "/market/infrastructure/watertanks",
        component: (props: ChildPageContentConfig) => <WaterTanks {...props} />,
      },
    ],
  },
  {
    imgSrc: "/images/market/technicaltextile.webp",
    title: "Technical Textiles",
    link: "/market/technicaltextiles",
    component: (props: ChildPageContentConfig) => <Packing {...props} />,
    children: [
      {
        imgSrc: "/images/market/technicaltextiles/roofing.webp",
        title: "Roofing",
        link: "/market/technicaltextiles/roofing",
        component: (props: ChildPageContentConfig) => <Roofing {...props} />,
      },
      {
        imgSrc: "/images/market/technicaltextiles/non woven.webp",
        title: "Non Woven Fabrics",
        link: "/market/technicaltextiles/nonwovenfabrics",
        component: (props: ChildPageContentConfig) => (
          <NonWovenFabrics {...props} />
        ),
      },
      {
        imgSrc: "/images/market/technicaltextiles/weed mat.webp",
        title: "Weed mat",
        link: "/market/technicaltextiles/weedmat",
        component: (props: ChildPageContentConfig) => <WeedMat {...props} />,
      },
    ],
  },
  {
    imgSrc: "/images/market/moulding.webp",
    title: "Others",
    link: "/market/others",
    component: (props: ChildPageContentConfig) => <Packing {...props} />,
    children: [
      {
        imgSrc: "/images/market/others/moulding.webp",
        title: "Moulding",
        link: "/market/others/moulding",
        component: (props: ChildPageContentConfig) => <Moulding {...props} />,
      },
      {
        imgSrc: "/images/market/others/blown film.webp",
        title: "Blown Film",
        link: "/market/others/blownfilm",
        component: (props: ChildPageContentConfig) => <BlownFilm {...props} />,
      },
      {
        imgSrc: "/images/market/others/toys.webp",
        title: "Toys",
        link: "/market/others/toys",
        component: (props: ChildPageContentConfig) => <Toys {...props} />,
      },
      {
        imgSrc: "/images/market/others/PP Sheet.webp",
        title: "PP Sheet",
        link: "/market/others/ppsheet",
        component: (props: ChildPageContentConfig) => <PPSheet {...props} />,
      },
    ],
  },
];
export const MarketingCardDataSource = {
  wrapper: { className: "markets-wrapper content5-wrapper" },
  page: { className: "markets content5" },
  OverPack: { playScale: 0.3, className: "market-overpack-block" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      { name: "title", children: "Markets", className: "title-h1" },
      {
        name: "content",
        className: "title-content",
        children: "Our products are used in following markets",
      },
    ],
  },
  block: {
    className: "content5-img-wrapper",
    gutter: 16,
    children: MarketingData.map((data, index) => {
      return {
        name: "block" + index,
        className: "block",
        link: data.link,
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children: data.imgSrc,
          },
          content: { children: data.title },
        },
      };
    }),
  },
};
