export const WhatWeDoDataSource = {
  wrapper: { className: "whatwedo-about-us-wrapper whatwedo-about-us-wrapper" },
  OverPack: { className: "whatwedo-about-us whatwedo-about-us" },
  textWrapper: { className: "whatwedo-about-us-text", xs: 24, md: 14 },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: "What We Do",
        className: "title-h1",
      },
      {
        name: "content",
        className: "title-content",
        children:
          "Elevating Plastics: A Closer Look at Our Specialized Services",
      },
    ],
  },
  img: {
    children: "/images/aboutus/what-we-do.webp",
    className: "whatwedo-about-us-img",
    xs: 24,
    md: 10,
  },
  block: {
    children: [
      {
        name: "block0",
        img: {
          children: "/images/aboutus/idea.webp",
          className: "whatwedo-about-us-icon",
        },
        title: { className: "whatwedo-about-us-title", children: "Innovation" },
        content: {
          className: "whatwedo-about-us-content",
          children:
            "We invest heavily in research and development, staying ahead of market trends to offer our clients the latest and most innovative masterbatch solutions. Our state-of-the-art facilities are equipped with cutting-edge technology, allowing us to create products that not only meet but exceed industry standards.",
        },
      },
      {
        name: "block1",
        img: {
          className: "whatwedo-about-us-icon",
          children: "/images/aboutus/high-quality.webp",
        },
        title: { className: "whatwedo-about-us-title", children: "Quality Assurance" },
        content: {
          className: "whatwedo-about-us-content",
          children:
            "Quality is the cornerstone of our operations. We adhere to stringent quality control measures at every stage of production, ensuring that our masterbatches meet the highest benchmarks for consistency, color accuracy, and performance.",
        },
      },
      {
        name: "block2",
        img: {
          className: "whatwedo-about-us-icon",
          children: "/images/aboutus/color-selection.webp",
        },
        title: { className: "whatwedo-about-us-title", children: "Customization" },
        content: {
          className: "whatwedo-about-us-content",
          children:
            "We understand that one size does not fit all. Our commitment to customization allows us to tailor our masterbatch solutions to your specific requirements. Whether you need unique color formulations, special effects, or enhanced functionality, we have the expertise to bring your vision to life.",
        },
      },
      {
        name: "block3",
        img: {
          className: "whatwedo-about-us-icon",
          children: "/images/aboutus/planet-earth.webp",
        },
        title: { className: "whatwedo-about-us-title", children: "Sustainability" },
        content: {
          className: "whatwedo-about-us-content",
          children:
            "We recognize the importance of environmental stewardship. Our masterbatch formulations are developed with sustainability in mind, using eco-friendly and recyclable materials. We strive to minimize our carbon footprint and contribute to a greener, more sustainable future.",
        },
      },
    ],
  },
};
