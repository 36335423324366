import { Typography, Col, Row } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../../types/cardsType";
import { Image } from "antd";
import Title from "antd/es/typography/Title";

const { Text } = Typography;
const CorrosionInhabitors = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  if (content.ismobile)
    return (
      <>
        <Title level={4}>Corrosion Inhabitors Additive Master Batch</Title>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                VCIs are used as additives to packaging materials, including
                paper and plastic. The presence of these chemicals protects the
                packaged items from corrosion. The packaging is common for
                automotive, steel, metal, and military packaging.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
      </>
    );
  return (
    <>
      <Row
        key="content"
        className={content.className}
        gutter={content.gutter}
        justify="space-between"
        align="middle"
      >
        <Col className={text.className} span={16}>
          <Typography>
            <Paragraph>
              <Text strong>Corrosion Inhabitors Additive Master Batch: </Text>
              VCIs are used as additives to packaging materials, including paper
              and plastic. The presence of these chemicals protects the packaged
              items from corrosion. The packaging is common for automotive,
              steel, metal, and military packaging.
            </Paragraph>
          </Typography>
        </Col>
        <Col className={img.className} span={8}>
          <Image
            src={content.img.children}
            alt="img"
            width={"100%"}
            preview={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default CorrosionInhabitors;
