import { Typography, Col, Row } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../../types/cardsType";
import Title from "antd/lib/typography/Title";

const { Text } = Typography;
const OpticalBrightner = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  if (content.ismobile)
    return (
      <>
        <Title level={4}>Optical brighteners Additive Master Batch</Title>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                Optical brighteners, optical brightening agents (OBAs),
                fluorescent brightening agents (FBAs), or fluorescent whitening
                agents (FWAs), are chemical compounds that absorb light in the
                ultraviolet and violet region (usually 340-370 nm) of the
                electromagnetic spectrum, and re-emit light in the blue region
                (typically 420-470 nm) through the phenomenon of fluorescence.
                These additives are often used to enhance the appearance of
                color of fabric and paper, causing a "whitening" effect”.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
      </>
    );
  return (
    <>
      <Row
        key="content"
        className={content.className}
        gutter={content.gutter}
        justify="space-between"
        align="middle"
      >
        <Col className={text.className} span={16}>
          <Typography>
            <Paragraph>
              <Text strong>Optical brighteners Additive Master Batch: </Text>
              Optical brighteners, optical brightening agents (OBAs),
              fluorescent brightening agents (FBAs), or fluorescent whitening
              agents (FWAs), are chemical compounds that absorb light in the
              ultraviolet and violet region (usually 340-370 nm) of the
              electromagnetic spectrum, and re-emit light in the blue region
              (typically 420-470 nm) through the phenomenon of fluorescence.
              These additives are often used to enhance the appearance of color
              of fabric and paper, causing a "whitening" effect”.
            </Paragraph>
          </Typography>
        </Col>
        <Col className={img.className} span={8}>
          <img
            src={content.img.children}
            width="100%"
            alt="img"
            loading="lazy"
          />
        </Col>
      </Row>
    </>
  );
};

export default OpticalBrightner;
