import "./less/antMotionStyle.less";
import { HomeState } from "../types/HomeState";
import HomePage from "./HomePage";
import { useEffect, useState } from "react";
import { cacheImages } from "../LoadingPage/imagePreloadUtil";
import LoadingScreen from "../LoadingPage";

export default function Home({ ismobile }: HomeState) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    cacheImages(
      [
        "/images/logo.webp",
        "/images/logobanner.webp",
        "/images/navbar logo.webp",
        "/static/media/banner 1.webp",
        "/static/media/banner 2.jpg",
        "/static/media/banner 3.jpeg",
        "/static/media/banner 5.jpg",
        "/images/productsandservices/countdown.webp",
        "/images/productsandservices/Customized Products.webp",
        "/images/productsandservices/experienced staff.webp",
        "/images/productsandservices/fast delivery.webp",
        "/images/productsandservices/proper infra.webp",
        "/images/productsandservices/quality product.webp",
      ],
      setIsLoading
    );
  }, []);
  if (isLoading) return <LoadingScreen ismobile={ismobile} />;

  return <HomePage ismobile={ismobile} />;
}
