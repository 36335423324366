import React from "react";
import { Tabs, TabsProps } from "antd";

import { OverPack } from "rc-scroll-anim";
import { useNavigate } from "react-router-dom";
import { TweenOneGroup } from "rc-tween-one";
import {
  MarketingChildPageDataSource,
  MarketingChildPageDataSourceItem,
  MarketingChildPageDataSourceType,
} from "../data-source/MarketingChildPageDataSource";
import { MarketingData } from "../data-source/MarketingCardsDataSource";

interface MarketingChildPageProps {
  name: string;
  ismobile: boolean;
}
const MarketingChildPage: React.FC<MarketingChildPageProps> = ({
  name,
  ismobile,
}) => {
  const navigate = useNavigate();
  const paths = name.split("/");
  const parentTabIndex =
    MarketingData.findIndex((e) => e.link === "/market/" + paths[0]) + 1;
  const parentData = MarketingData[parentTabIndex - 1];
  const childTabIndex =
    paths[1] && parentData.children
      ? parentData.children.findIndex(
          (e) => e.link === "/market/" + paths[0] + "/" + paths[1]
        ) + 1
      : 1;

  const onChange = (key: string) => {
    const newLink = MarketingData[Number(key) - 1].link;
    navigate(newLink || "");
  };
  const onSubchildChange = (key: string) => {
    const newLink = parentData.children
      ? parentData.children[Number(key) - 1].link
      : parentData.link;
    navigate(newLink || "");
  };

  const dataSource: MarketingChildPageDataSourceType =
    MarketingChildPageDataSource(ismobile);
  const getBlockSubChildren = (
    item: MarketingChildPageDataSourceItem,
    i: number,
    currentTab: number
  ) => {
    const { content } = item;
    return {
      key: String(i + 1),
      className: item.className,
      label: item.name,
      children: (
        <div>
          <TweenOneGroup
            enter={{
              y: 30,
              delay: 300,
              opacity: 0,
              type: "from",
              ease: "easeOutQuad",
            }}
            component=""
            key={i + 1}
          >
            {currentTab === i + 1 && <div>{content}</div>}
          </TweenOneGroup>
        </div>
      ),
    };
  };
  const getBlock = (item: MarketingChildPageDataSourceItem, i: number) => {
    const { content, children } = item;
    return {
      key: String(i + 1),
      className: item.className,
      label: item.name,
      children: (
        <div>
          <TweenOneGroup
            enter={{
              y: 30,
              delay: 300,
              opacity: 0,
              type: "from",
              ease: "easeOutQuad",
            }}
            component=""
            key={i + 1}
          >
            <div>
              {parentTabIndex === i + 1 &&
                (children ? (
                  <Tabs
                    key="tabsSubChild"
                    type="card"
                    centered={!ismobile}
                    onChange={onSubchildChange}
                    activeKey={String(childTabIndex)}
                    tabPosition={ismobile ? "top" : "left"}
                    items={
                      Array.isArray(item.children)
                        ? item.children?.map((elem, index) =>
                            getBlockSubChildren(elem, index, childTabIndex)
                          )
                        : [getBlockSubChildren(item, 0, 1)]
                    }
                  ></Tabs>
                ) : (
                  <div>{content}</div>
                ))}
            </div>
          </TweenOneGroup>
        </div>
      ),
    };
  };
  const tabs: TabsProps["items"] = dataSource.block.children.map(getBlock);
  return (
    <OverPack {...dataSource.OverPack}>
      <TweenOneGroup
        key="tabs"
        enter={{
          y: 30,
          opacity: 0,
          delay: 200,
          type: "from",
        }}
        leave={{ y: 30, opacity: 0 }}
        {...dataSource.tabsWrapper}
      >
        <div>
          <Tabs
            key="tabs"
            type="card"
            centered={!ismobile}
            onChange={onChange}
            activeKey={String(parentTabIndex)}
            items={tabs}
          ></Tabs>
        </div>
      </TweenOneGroup>
    </OverPack>
  );
};

export default MarketingChildPage;
