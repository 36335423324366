import { Col, Row, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../types/cardsType";

const Agriculture = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  if (content.ismobile)
    return (
      <>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                Plastic masterbatch plays a crucial role in the field of
                agriculture, offering innovative solutions that contribute to
                improved crop yields, reduced environmental impact, and enhanced
                farm operations. One of the primary applications of plastic
                masterbatch in agriculture is in the production of agricultural
                films and mulch. These films, typically made from polyethylene,
                are used for various purposes, such as greenhouse covering, soil
                mulching, and crop protection. Masterbatch additives are
                incorporated into these films to enhance their performance and
                durability.
              </Paragraph>
              <Paragraph style={{ textAlign: "justify" }}>
                One key application of plastic masterbatch in agriculture is in
                the manufacturing of UV-stabilized films. These films are
                designed to withstand prolonged exposure to sunlight without
                degrading, which is essential for greenhouse structures. By
                adding UV stabilizers through masterbatch, the films can
                maintain their structural integrity and transparency, allowing
                for optimal light transmission while protecting crops from
                harmful UV radiation. This not only creates a controlled
                environment for plant growth but also extends the lifespan of
                the greenhouse film, reducing replacement costs for farmers.
              </Paragraph>
              <Paragraph style={{ textAlign: "justify" }}>
                Another critical use of plastic masterbatch in agriculture is in
                the production of mulch films. Mulching is a common practice in
                modern agriculture, where plastic films are placed on the soil
                surface to control weed growth, conserve soil moisture, and
                regulate soil temperature. Masterbatch additives can provide
                mulch films with properties like biodegradability, enhancing
                their sustainability by ensuring that the plastic breaks down
                into environmentally friendly byproducts over time. This reduces
                the need for manual removal and disposal of plastic mulch,
                making it a more eco-friendly option for farmers.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
      </>
    );
  return (
    <Row key="content" className={content.className} gutter={content.gutter}>
      <Col className={text.className} xs={text.xs} md={text.md}>
        <Typography>
          <Paragraph style={{ textAlign: "justify" }}>
            Plastic masterbatch plays a crucial role in the field of
            agriculture, offering innovative solutions that contribute to
            improved crop yields, reduced environmental impact, and enhanced
            farm operations. One of the primary applications of plastic
            masterbatch in agriculture is in the production of agricultural
            films and mulch. These films, typically made from polyethylene, are
            used for various purposes, such as greenhouse covering, soil
            mulching, and crop protection. Masterbatch additives are
            incorporated into these films to enhance their performance and
            durability.
          </Paragraph>
          <Paragraph style={{ textAlign: "justify" }}>
            One key application of plastic masterbatch in agriculture is in the
            manufacturing of UV-stabilized films. These films are designed to
            withstand prolonged exposure to sunlight without degrading, which is
            essential for greenhouse structures. By adding UV stabilizers
            through masterbatch, the films can maintain their structural
            integrity and transparency, allowing for optimal light transmission
            while protecting crops from harmful UV radiation. This not only
            creates a controlled environment for plant growth but also extends
            the lifespan of the greenhouse film, reducing replacement costs for
            farmers.
          </Paragraph>
          <Paragraph style={{ textAlign: "justify" }}>
            Another critical use of plastic masterbatch in agriculture is in the
            production of mulch films. Mulching is a common practice in modern
            agriculture, where plastic films are placed on the soil surface to
            control weed growth, conserve soil moisture, and regulate soil
            temperature. Masterbatch additives can provide mulch films with
            properties like biodegradability, enhancing their sustainability by
            ensuring that the plastic breaks down into environmentally friendly
            byproducts over time. This reduces the need for manual removal and
            disposal of plastic mulch, making it a more eco-friendly option for
            farmers.
          </Paragraph>
        </Typography>
      </Col>
      <Col className={img.className} xs={img.xs} md={img.md}>
        <img src={content.img.children} width="100%" alt="img" loading="lazy" />
      </Col>
    </Row>
  );
};

export default Agriculture;
