import { Row, Col, Typography, Divider } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { getChildrenToRender } from "./utils";
import {
  ProductsCardsDataSource,
  ProductsData,
} from "../data-source/ProductsCardsDataSource";
import { HomeState } from "../types/HomeState";
import { useNavigate } from "react-router-dom";
import ProductsChildPage from "./ProductsChildPage";
const dataSource = ProductsCardsDataSource;

const ProductsCards = (props: HomeState) => {
  const navigate = useNavigate();

  const getChildrenToRenderIn = (data: any[]) => (
    <Row gutter={[12, 12]} justify="center">
      {data.map((item, index) => {
        return (
          <Col
            md={6}
            xs={12}
            style={{ display: "flex" }}
            onClick={() => navigate(item.link)}
            key={index}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                transition: "box-shadow 0.2s,border-color 0.2s",
                border: "1px solid #f0f0f0",
                borderRadius: "8px",
                background: "#ffffff",
                overflow: "hidden",
                width: "100%",
              }}
            >
              <img
                alt={item.children.content.children}
                src={item.children.img.children}
                style={{
                  width: "100%",
                  objectFit: "cover",
                  height: "200px",
                }}
              />

              <div
                style={{
                  alignSelf: "center",
                  marginTop: "auto",
                }}
              >
                <Divider style={{ margin: "0px auto 10px auto" }} />
                <Typography.Title level={props.ismobile ? 5 : 4}>
                  {item.children.content.children}
                </Typography.Title>
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
  const childrenToRender = getChildrenToRenderIn(dataSource.block.children);
  const paths = props.childname?.toLowerCase().split("/") || [];
  return (
    <div className="products-childpage">
      <div {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <div key="title" {...dataSource.titleWrapper}>
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
          </div>
          {props.childname &&
          ProductsData.some((e) => e.link === "/products/" + paths[0]) ? (
            <ProductsChildPage
              name={props.childname}
              ismobile={props.ismobile}
            ></ProductsChildPage>
          ) : (
            <OverPack {...dataSource.OverPack}>
              <TweenOneGroup
                component={Row}
                key="ul"
                enter={{
                  y: "+=30",
                  opacity: 0,
                  type: "from",
                  ease: "easeInOutQuad",
                }}
                leave={{ y: "+=30", opacity: 0, ease: "easeInOutQuad" }}
                {...dataSource.block}
              >
                {childrenToRender}
              </TweenOneGroup>
            </OverPack>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductsCards;
