import "./less/antMotionStyle.less";
import { HomeState } from "../types/HomeState";
import CompanyProfile from "./CompanyProfile";
import CompanyBannerLogo from "./CompanyBannerLogo";
import LoadingScreen from "../LoadingPage";
import { cacheImages } from "../LoadingPage/imagePreloadUtil";
import { useEffect, useState } from "react";

export default function ContactUs({ ismobile }: HomeState) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    cacheImages(
      ["/images/home/banner 1.webp", "/images/contactus-bg.webp"],
      setIsLoading
    );
  }, []);
  if (isLoading) return <LoadingScreen ismobile={ismobile} />;

  return (
    <>
      <CompanyBannerLogo ismobile={ismobile} />
      <CompanyProfile ismobile={ismobile} />
    </>
  );
}
