import { Typography, Col, Row } from "antd";
import { ChildPageContentConfig } from "../../../types/cardsType";
import Paragraph from "antd/es/typography/Paragraph";
import { Image } from "antd";
import Title from "antd/es/typography/Title";
const { Text } = Typography;

const AntiBlock = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  if (content.ismobile)
    return (
      <>
        <Title level={4}>Anti Block Additive Master Batch</Title>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                Anti-blocking is a term used to describe measures to prevent
                film sheets to stick together. Polyolefin films tend to adhere
                to each other due to strong van der Waals interaction or
                electrostatic charges when being in close contact (adjacent
                layers).
              </Paragraph>
            </Typography>
          </Col>
        </Row>
      </>
    );
  return (
    <>
      <Row
        key="content"
        className={content.className}
        gutter={content.gutter}
        justify="space-between"
        align="middle"
      >
        <Col className={text.className} span={14}>
          <Typography>
            <Paragraph>
              <Text strong>Anti Block Additive Master Batch: </Text>
              Anti-blocking is a term used to describe measures to prevent film
              sheets to stick together. Polyolefin films tend to adhere to each
              other due to strong van der Waals interaction or electrostatic
              charges when being in close contact (adjacent layers).
            </Paragraph>
          </Typography>
        </Col>
        <Col className={img.className} span={10}>
          <Image
            src={content.img.children}
            alt="img"
            preview={false}
            width={"100%"}
          />
        </Col>
      </Row>
    </>
  );
};

export default AntiBlock;
