export const TestimonialsDataSource = {
  wrapper: { className: "home-page-wrapper teams0-wrapper" },
  OverPack: { playScale: 0.3, className: "home-page teams0" },
  BannerAnim: {
    className: "banner-anim",
    children: [
      {
        name: "elem0",
        className: "teams0-banner-user-elem",
        titleWrapper: {
          className: "teams0-content-wrapper",
          children: [
            {
              name: "content",
              children:
                '"Outstanding masterbatch quality and exceptional customer service make Navkar Polymers our top choice."',
              className: "teams0-content",
            },
            {
              name: "content2",
              children: "Mr. Jitesh Yadav",
              className: "teams0-content2",
            },
          ],
        },
      },
      {
        name: "elem1",
        className: "teams0-banner-user-elem",
        titleWrapper: {
          className: "teams0-content-wrapper",
          children: [
            {
              name: "content",
              children:
                '"Navkar Polymers sets the standard for innovative masterbatch solutions with their diverse product range."',
              className: "teams0-content",
            },
            {
              name: "content2",
              children: "Mr. Aadit Tibrewal",
              className: "teams0-content2",
            },
          ],
        },
      },
      {
        name: "elem2",
        className: "teams0-banner-user-elem",
        titleWrapper: {
          className: "teams0-content-wrapper",
          children: [
            {
              name: "content",
              children:
                '"Reliable and environmentally conscious, Navkar Polymers is the go-to partner for sustainable masterbatch manufacturing."',
              className: "teams0-content",
            },
            {
              name: "content2",
              children: "Mr. Trambak Makwana",
              className: "teams0-content2",
            },
          ],
        },
      },
      {
        name: "elem3",
        className: "teams0-banner-user-elem",
        titleWrapper: {
          className: "teams0-content-wrapper",
          children: [
            {
              name: "content",
              children:
                '"Impressed by Navkar Polymers\'s commitment to quality, their masterbatches have significantly enhanced our products."',
              className: "teams0-content",
            },
            {
              name: "content2",
              children: "Mr. Ravi Kadiwar",
              className: "teams0-content2",
            },
          ],
        },
      },
    ],
  },
};
