const clientImgNames = [
  "AGPL.webp",
  "Ananta Synthetic Innovations.webp",
  "Balson polyplast private limite.webp",
  "Bonus plastics.webp",
  "Captain.webp",
  "Centurion.webp",
  "Dayana.webp",
  "Drip India irrigation private limited.webp",
  "Greenage industries.webp",
  "GVM.webp",
  "Laxmo.webp",
  "Macfil global private limited.webp",
  "Mayur Woven private limited.webp",
  "Ozolin.webp",
  "Patco.webp",
  "Radhadarshan Petropack.webp",
  "Sabar.webp",
  "Salasar balaji mahijda.webp",
  "Sona polyplast pvt ltd.webp",
  "Spinner.webp",
  "Swami.webp",
  "Tara.webp",
  "Tech plastfab.webp",
  "Wadhwa.webp",
  "Welpack.webp",
];

export const ClientsDataSource = {
  wrapper: { className: "home-page-wrapper content12-wrapper" },
  page: { className: "home-page content12" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "image",
        children:
          "https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg",
        className: "title-image",
      },
      { name: "title", children: "Clients", className: "title-h1" },
    ],
  },
  block: {
    className: "img-wrapper",
    children: clientImgNames.map((e, idx) => {
      return {
        name: "block" + idx,
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "block-content" },
          img: {
            maxHeight: "100%",
            maxWidth: "90%",
            children: "/images/clients/" + e,
          },
        },
      };
    }),
  },
};
