import "./less/loader.less";
import { Image } from "antd";
import { HomeState } from "../types/HomeState";

export default function LoadingScreen({ ismobile }: HomeState) {
  return (
    <div className="loader-body" style={{ height: "100vh" }}>
      <div className="vertical-centered-box">
        <div className="content">
          <div className="loader-circle"></div>
          <div className="loader-line-mask">
            <div className="loader-line"></div>
          </div>
          <div className="loader-image-logo">
            <Image src="/images/logo.webp" alt="logo" preview={false} />
          </div>
        </div>
      </div>
    </div>
  );
}
