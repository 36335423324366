import { Typography, Row, Col, List } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../../types/cardsType";
import Title from "antd/es/typography/Title";
const { Text } = Typography;

const CementBags = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  const data = ["Green", "Yellow", "White", "Black", "Red"];
  if (content.ismobile)
    return (
      <>
        <Title level={4}>Cement bags</Title>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                In cement bag manufacturing, plastic masterbatch is often
                incorporated into the bag material to impart essential
                properties. Firstly, it enhances the bag's durability and
                strength, ensuring that it can withstand the weight of the
                cement and the rigors of transportation without tearing or
                breaking. This is particularly important in the construction
                industry, where cement bags are frequently handled and
                transported in bulk.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
        <Row
          key="content"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
        >
          <List
            header={<Text strong>Suggested Masterbatch:</Text>}
            // bordered
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                {"> "} <Text>{item}</Text>
              </List.Item>
            )}
            grid={{ column: 2 }}
          />
        </Row>
      </>
    );
  return (
    <>
      <Row key="content" style={{ paddingBlockStart: "20px" }}>
        <Col className={text.className}>
          <Typography>
            <Paragraph style={{ textAlign: "justify" }}>
              <Text strong>Cement bags: </Text> In cement bag manufacturing,
              plastic masterbatch is often incorporated into the bag material to
              impart essential properties. Firstly, it enhances the bag's
              durability and strength, ensuring that it can withstand the weight
              of the cement and the rigors of transportation without tearing or
              breaking. This is particularly important in the construction
              industry, where cement bags are frequently handled and transported
              in bulk.
            </Paragraph>
          </Typography>
        </Col>
      </Row>
      <Row
        className={content.className}
        gutter={content.gutter}
        justify="space-between"
        align="middle"
      >
        <Col span={12}>
          <Row>
            <Col>
              <List
                header={<Text strong>Suggested Masterbatch:</Text>}
                // bordered
                dataSource={data}
                renderItem={(item) => (
                  <List.Item>
                    {"> "} <Text>{item}</Text>
                  </List.Item>
                )}
                grid={{ column: 1, gutter: 2 }}
              />
            </Col>
          </Row>
        </Col>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="100%"
            alt="img"
            loading="lazy"
          />
        </Col>
      </Row>
    </>
  );
};

export default CementBags;
