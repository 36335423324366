import { Typography, Col, Row } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../../types/cardsType";
import Title from "antd/es/typography/Title";

const { Text } = Typography;
const Slip = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  if (content.ismobile)
    return (
      <>
        <Title level={4}>UV Additive Master Batch</Title>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                “Slip” additives are used to reduce a film's resistance to
                sliding over itself or parts of converting equipment.
                Commercially important slips can be found in the chemical family
                known as amides, and are typically referred to as “fast bloom”
                (oleamide) and “slow bloom” (erucamide) additives.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
      </>
    );
  return (
    <>
      <Row
        key="content"
        className={content.className}
        gutter={content.gutter}
        justify="space-between"
        align="middle"
      >
        <Col className={text.className} span={14}>
          <Typography>
            <Paragraph>
              <Text strong>UV Additive Master Batch: </Text>
              “Slip” additives are used to reduce a film's resistance to sliding
              over itself or parts of converting equipment. Commercially
              important slips can be found in the chemical family known as
              amides, and are typically referred to as “fast bloom” (oleamide)
              and “slow bloom” (erucamide) additives.
            </Paragraph>
          </Typography>
        </Col>
        <Col className={img.className} span={10}>
          <img
            src={content.img.children}
            width="100%"
            alt="img"
            loading="lazy"
          />
        </Col>
      </Row>
    </>
  );
};

export default Slip;
