import "./less/antMotionStyle.less";
import { HomeState } from "../types/HomeState";

import ProductsAndServices from "../Home/ProductsAndServices";
import LoadingScreen from "../LoadingPage";
import { cacheImages } from "../LoadingPage/imagePreloadUtil";
import { useEffect, useState } from "react";
import VisionMission from "./VisionMission";
import WhoWeAre from "./WhoWeAre";
import WhatWeDo from "./WhatWeDo";

export default function AboutUs({ ismobile }: HomeState) {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    cacheImages(
      [
        "/images/productsandservices/countdown.webp",
        "/images/productsandservices/Customized Products.webp",
        "/images/productsandservices/experienced staff.webp",
        "/images/productsandservices/fast delivery.webp",
        "/images/productsandservices/proper infra.webp",
        "/images/productsandservices/quality product.webp",
      ],
      setIsLoading
    );
  }, []);
  if (isLoading) return <LoadingScreen ismobile={ismobile} />;

  return (
    <>
      {/* <HomeAboutUs ismobile={ismobile} /> */}
      <VisionMission ismobile={ismobile} />
      <WhoWeAre ismobile={ismobile} />
      <WhatWeDo ismobile={ismobile} />
      <ProductsAndServices ismobile={ismobile} />
      {/* <BrandCapacity ismobile={ismobile} /> */}
    </>
  );
}
