export const ContactUsFormDataSource = {
  OverPack: {
    className: "footer-wrapper contactusform-wrapper",
    // playScale: 0.3,
  },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>Contact Us</p>
          </span>
        ),
        className: "title-h1",
      },
    ],
  },
};
