import { useParams } from "react-router-dom";
import MarketingCards from "../Home/MarketingCards";
import { HomeState } from "../types/HomeState";

import "./less/antMotionStyle.less";
import LoadingScreen from "../LoadingPage";
import { useEffect, useState } from "react";
import { cacheImages } from "../LoadingPage/imagePreloadUtil";

export default function Markets({ ismobile }: HomeState) {
  const id = useParams()["*"];
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    cacheImages(
      [
        "/images/market/agriculture.webp",
        "/images/market/Infra.webp",
        "/images/market/moulding.webp",
        "/images/market/packaging.webp",
        "/images/market/technicaltextile.webp",
        "/images/market/textileandfibres.webp",
        "/images/market/wovensack.webp",
        "/images/market/agriculture/Drip.webp",
        "/images/market/agriculture/Green Shade net 2.webp",
        "/images/market/agriculture/Mulch ilm.webp",
        "/images/market/agriculture/pond.webp",
        "/images/market/infra/HDPE Pipe.webp",
        "/images/market/infra/plb-duct-pipes.webp",
        "/images/market/infra/water tanks.webp",
        "/images/market/others/blown film.webp",
        "/images/market/others/moulding.webp",
        "/images/market/others/PP Sheet.webp",
        "/images/market/others/toys.webp",
        "/images/market/technicaltextiles/non woven.webp",
        "/images/market/technicaltextiles/roofing.webp",
        "/images/market/technicaltextiles/weed mat.webp",
        "/images/market/textileandfibre/monofilament.webp",
        "/images/market/textileandfibre/pp-multifilament-yarn-lg.webp",
        "/images/market/wovensack/cement bags.webp",
        "/images/market/wovensack/leno-bags.webp",
        "/images/market/wovensack/Tarpaulin.webp",
      ],
      setIsLoading
    );
  }, []);
  if (isLoading) return <LoadingScreen ismobile={ismobile} />;
  return <MarketingCards ismobile={ismobile} childname={id} />;
}
