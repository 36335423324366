import { Typography, Row, Col, List } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../../types/cardsType";
import Title from "antd/es/typography/Title";
const { Text } = Typography;

const NonWovenFabrics = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  const data = ["Red", "Green", "Blue", "White", "Pink", "Yellow"];
  if (content.ismobile)
    return (
      <>
        <Title level={4}>Non Woven Fabrics</Title>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                Nonwoven fabric is a fabric-like material made from staple fibre
                (short) and long fibres (continuous long), bonded together by
                chemical, mechanical, heat or solvent treatment. The term is
                used in the textile manufacturing industry to denote fabrics,
                such as felt, which are neither woven nor knitted.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
        <Row
          key="content"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
        >
          <List
            header={<Text strong>Suggested Masterbatch:</Text>}
            // bordered
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                {"> "} <Text>{item}</Text>
              </List.Item>
            )}
            grid={{ column: 2 }}
          />
        </Row>
      </>
    );
  return (
    <>
      <Row key="content" style={{ paddingBlockStart: "20px" }}>
        <Col className={text.className}>
          <Typography>
            <Paragraph style={{ textAlign: "justify" }}>
              <Text strong>Non Woven Fabrics: </Text> Nonwoven fabric is a
              fabric-like material made from staple fibre (short) and long
              fibres (continuous long), bonded together by chemical, mechanical,
              heat or solvent treatment. The term is used in the textile
              manufacturing industry to denote fabrics, such as felt, which are
              neither woven nor knitted.
            </Paragraph>
          </Typography>
        </Col>
      </Row>
      <Row
        className={content.className}
        gutter={content.gutter}
        justify="space-between"
        align="middle"
      >
        <Col span={12}>
          <Row>
            <Col>
              <List
                header={<Text strong>Suggested Masterbatch:</Text>}
                // bordered
                dataSource={data}
                renderItem={(item) => (
                  <List.Item>
                    {"> "} <Text>{item}</Text>
                  </List.Item>
                )}
                grid={{ column: 1, gutter: 2 }}
              />
            </Col>
          </Row>
        </Col>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="100%"
            alt="img"
            loading="lazy"
          />
        </Col>
      </Row>
    </>
  );
};

export default NonWovenFabrics;
