export const FooterDataSource = {
  wrapper: { className: "footer-wrapper footer1-wrapper" },
  OverPack: { className: "footer1", playScale: 0.2 },
  block: {
    className: "footer",
    gutter: 0,
    children: [
      {
        name: "block0",
        xs: 24,
        md: 12,
        className: "block",
        title: {
          className: "logo",
          children: "/images/navkar-logo-mobile-scrolled.webp",
        },
        childWrapper: {
          className: "slogan",
          children: [
            {
              name: "content0",
              type: "address",
              action: "https://maps.app.goo.gl/wRPGQd52Le59KfSAA",
              children:
                "76, Shree Krishna Industrial Estate, Near Radheyshyam Industrial Estate, S.P Ring Road, Odhav, Ahmedabad - 382415",
            },
            {
              name: "content2",
              type: "call",
              action: "tel:+919909234902&body=Hi",
              children: "+919909234902",
            },
            {
              name: "content2",
              type: "call",
              action: "tel:+919909234903&body=Hi",
              children: "+919909234903",
            },
            {
              name: "content1",
              type: "whatsapp",
              action: "https://wa.me/919104106339?text=Hi",
              children: "+919104106339",
            },
            {
              name: "content3",
              type: "email",
              action: "mailto:navkarpolymer@gmail.com",
              children: "navkarpolymer@gmail.com",
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: "copyright-wrapper" },
  copyrightPage: { className: "footer" },
  copyright: {
    className: "copyright",
    children: <span>©2024 by Sneh Kothari All Rights Reserved</span>,
  },
};
