import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import QueueAnim from "rc-queue-anim";
import { HomeState } from "../types/HomeState";
import { CompanyProfileDataSource } from "../data-source/CompanyProfileDataSource";
import { Col, Divider, Row, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
const { Text } = Typography;

const CompanyProfile = (props: HomeState) => {
  const dataSource = CompanyProfileDataSource;
  if (props.ismobile)
    return (
      <OverPack {...dataSource.OverPack}>
        <QueueAnim
          type="bottom"
          leaveReverse
          key="page"
          delay={[0, 100]}
          {...dataSource.titleWrapper}
        >
          <div style={{ margin: "0px 20px 0px 33%" }}>
            <Row gutter={3}>
              <Col span={13}>
                <Divider>Unit 1</Divider>
                76, Shree Krishna Industrial Estate, Near Radheyshyam Industrial
                Estate, S.P Ring Road, Odhav, Ahmedabad - 382415
              </Col>
              <Col span={11}>
                <Divider>Unit 2</Divider>
                Survey no. 906/2, Plot no. 4, Pasunj Kubadthal Road, Pasunj,
                Dascroi, Ahmedabad - 382430
              </Col>
            </Row>
            <Typography.Title level={4}>
              <Text strong>Contact Details: </Text>
              <br />
              <Text>
                Samkit Bagrecha - {<a href="tel:9909234902"> 9909234902</a>}
              </Text>
              <br />
              <Text>
                Sanyam Bagrecha - {<a href="tel:9909234903"> 9909234903</a>}
              </Text>
              <br />
              <Text>
                Bhavya Bhansali - {<a href="tel:9104106339"> 9104106339</a>}
              </Text>
            </Typography.Title>

            <Paragraph>
              <Text strong>Email Id: </Text>{" "}
              {
                <a href="mailto:navkarpolymer@gmail.com">
                  navkarpolymer@gmail.com
                </a>
              }
            </Paragraph>
          </div>
        </QueueAnim>
      </OverPack>
    );
  return (
    <OverPack {...dataSource.OverPack}>
      <QueueAnim
        type="bottom"
        leaveReverse
        key="page"
        delay={[0, 100]}
        {...dataSource.titleWrapper}
      >
        <div style={{ maxWidth: "40%", margin: "0px auto 0px auto" }}>
          <Divider>Unit 1</Divider>
          <Typography.Title level={4}>
            76, Shree Krishna Industrial Estate, {<br />} Near Radheyshyam
            Industrial Estate, S.P Ring Road, {<br />} Odhav, Ahmedabad - 382415
          </Typography.Title>

          <Divider>Unit 2</Divider>
          <Typography.Title level={4}>
            Survey no. 906/2, Plot no. 4, {<br />} Pasunj Kubadthal Road,{" "}
            Pasunj, {<br />} Dascroi, Ahmedabad - 382430
          </Typography.Title>
          <Typography.Title level={4}>
            <Text strong>Contact Details: </Text>
            <br />
            <Text>
              Samkit Bagrecha - {<a href="tel:9909234902"> 9909234902</a>}
            </Text>
            <br />
            <Text>
              Sanyam Bagrecha - {<a href="tel:9909234903"> 9909234903</a>}
            </Text>
            <br />
            <Text>
              Bhavya Bhansali - {<a href="tel:9104106339"> 9104106339</a>}
            </Text>
          </Typography.Title>

          <Paragraph>
            <Text strong>Email Id: </Text>{" "}
            {
              <a href="mailto:navkarpolymer@gmail.com">
                navkarpolymer@gmail.com
              </a>
            }
          </Paragraph>
        </div>
      </QueueAnim>
    </OverPack>
  );
};

export default CompanyProfile;
