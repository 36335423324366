import { useEffect, useState } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  Outlet,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Home from "./Home";

import Footer from "./Footer";
import "./Home/less/antMotionStyle.less";
import NavigationBar from "./Home/NavigationBar";
import AboutUs from "./About Us";
import HomeMap from "./Home/HomeMap";
import Products from "./Products";
import Markets from "./Markets";
import { HomeState } from "./types/HomeState";
import FloatButtonComponent from "./FloatButton";
import ContactUs from "./Contact Us";

export default function App() {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const ismobile = width <= 768;

  const BasicLayout = (props: HomeState) => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return (
      <>
        <NavigationBar ismobile={ismobile} />
        <Outlet />
        <HomeMap ismobile={ismobile} />
        <FloatButtonComponent />
        <Footer ismobile={ismobile} />
      </>
    );
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<BasicLayout ismobile={ismobile} />}>
          <Route path="aboutus" element={<AboutUs ismobile={ismobile} />} />
          <Route path="products" element={<Products ismobile={ismobile} />} />
          <Route path="products/*" element={<Products ismobile={ismobile} />} />
          <Route path="market" element={<Markets ismobile={ismobile} />} />
          <Route path="market/*" element={<Markets ismobile={ismobile} />} />
          <Route path="contactus" element={<ContactUs ismobile={ismobile} />} />
          <Route index element={<Home ismobile={ismobile} />} />
        </Route>
      </Routes>
    </Router>
  );
}
