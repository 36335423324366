import { ChildPageContentConfig } from "../types/cardsType";
import { ProductsData } from "./ProductsCardsDataSource";

function getConfig(
  imgSrc: string,
  ismobile: boolean,
  key: string
): ChildPageContentConfig {
  return {
    key: key,
    className: "content7-content",
    gutter: [16, 24],
    text: {
      className: "content7-text",
      md: 16,
      xs: 24,
    },
    img: {
      className: "content7-img",
      children: imgSrc,
      md: 12,
      xs: 24,
    },
    ismobile: ismobile,
  };
}

export interface ProductsChildPageDataSourceItem {
  name: string;
  className: string;
  tag: {
    className: string;
    text: {
      children: string;
      className: string;
    };
  };
  link: string;
  content: string;
  children: ProductsChildPageDataSourceItem[];
}

export interface ProductsChildPageDataSourceType {
  wrapper: { className: string };
  page: { className: string };
  OverPack: {};
  titleWrapper: {
    className: string;
    children: [
      {
        name: string;
        children: string;
        className: string;
      },
      {
        name: string;
        children: string;
      }
    ];
  };
  tabsWrapper: { className: string };
  block: {
    children: ProductsChildPageDataSourceItem[];
  };
}

export const ProductsChildPageDataSource = (
  ismobile: boolean
): ProductsChildPageDataSourceType => {
  return {
    wrapper: { className: "products-wrapper content7-wrapper" },
    page: { className: "products content7" },
    OverPack: {},
    titleWrapper: {
      className: "title-wrapper",
      children: [
        {
          name: "title",
          children: "Ant Financial Cloud Offers Professional Services",
          className: "title-h1",
        },
        {
          name: "content",
          children:
            "Built on the powerful foundation of Alibaba Cloud Computing",
        },
      ],
    },
    tabsWrapper: { className: "products-tabs-wrapper" },
    block: {
      children: ProductsData.map((e) => {
        return {
          name: e.title,
          tag: {
            className: "content7-tag",
            text: { children: e.title, className: "content7-tag-name" },
          },
          link: e.link,
          content: e.component(
            getConfig(e?.imgSrc || "", ismobile, e.link || "")
          ),
          children: e.children?.map((child) => {
            return {
              name: child.title,
              tag: {
                className: "content7-tag",
                text: { children: child.title, className: "content7-tag-name" },
              },
              link: child.link,
              content: child.component(
                getConfig(child?.imgSrc || "", ismobile, e.link || "")
              ),
            };
          }),
        } as ProductsChildPageDataSourceItem;
      }),
    },
  };
};
