import QueueAnim from "rc-queue-anim";
import { Row, Col } from "antd";
import CountUp from "react-countup";
import { HomeState } from "../types/HomeState";

const ProductsAndServices = (props: HomeState) => {
  const headingClassName = "countdownclasscenter smoothTransition";
  return (
    <div className="products-wrapper content0-wrapper">
      <div className="products content0">
        <div className="title-wrapper">
          <h1 key="0" className="title">
            Products and Services
          </h1>
        </div>
        {/* <OverPack playScale={0.3} className=""> */}
        <QueueAnim
          type="bottom"
          key="block2"
          leaveReverse
          // @ts-ignore
          component={Row}
          componentProps={{
            className: "content0-block-wrapper",
            align: "bottom",
          }}
        >
          <Col key="0" className="content0-block" md={8} xs={12}>
            <div className="content0-block-item">
              <img
                className="content0-block-icon"
                src="/images/productsandservices/Customized Products.webp"
                alt="Customized Products"
              />
              <div className="content0-block-title">Customized Products</div>
            </div>
          </Col>
          <Col key="1" className="content0-block" md={8} xs={12}>
            <div className="content0-block-item">
              <div className="content0-block-countdown">
                <CountUp className={headingClassName} end={30} duration={1} />
              </div>
              <div className="content0-block-title">Years of Experience</div>
            </div>
          </Col>
          <Col key="2" className="content0-block" md={8} xs={12}>
            <div className="content0-block-item">
              <img
                className="content0-block-icon"
                style={{ width: "70%", padding: "4% 0px" }}
                src="/images/productsandservices/fast delivery.webp"
                alt="Fast Delivery"
              />
              <div className="content0-block-title">Fast Delivery</div>
            </div>
          </Col>
          <Col key="3" className="content0-block" md={8} xs={12}>
            <div className="content0-block-item">
              <img
                className="content0-block-icon"
                src="/images/productsandservices/quality product.webp"
                alt="Quality Product"
              />
              <div className="content0-block-title">Quality Product</div>
            </div>
          </Col>
          <Col key="4" className="content0-block" md={8} xs={12}>
            <div className="content0-block-item">
              <img
                className="content0-block-icon"
                src="/images/productsandservices/proper infra.webp"
                alt="Proper Infrastructure"
              />
              <div className="content0-block-title">Proper Infrastructure</div>
            </div>
          </Col>
          <Col key="5" className="content0-block" md={8} xs={12}>
            <div className="content0-block-item">
              <img
                className="content0-block-icon"
                src="/images/productsandservices/experienced staff.webp"
                alt="Experienced Staff"
              />
              <div className="content0-block-title">Experienced Staff</div>
            </div>
          </Col>
        </QueueAnim>
        {/* </OverPack> */}
      </div>
    </div>
  );
};

export default ProductsAndServices;
