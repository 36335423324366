import { FloatButton } from "antd";
import { useState } from "react";
import { IoCallOutline } from "react-icons/io5";
import { BiSupport } from "react-icons/bi";
import {
  AiOutlineWhatsApp,
  AiOutlineMessage,
  AiOutlineMail,
} from "react-icons/ai";

const FloatButtonComponent: React.FC = () => {
  const [open, setOpen] = useState(true);

  return (
    <div style={{ left: "24px !important" }}>
      <FloatButton.Group
        open={open}
        trigger="click"
        icon={<BiSupport />}
        onClick={() => setOpen(!open)}
      >
        <FloatButton
          icon={<AiOutlineWhatsApp />}
          onClick={() =>
            window.open(
              "https://wa.me/919104106339?text=Hi",
              "_blank",
              "noreferrer"
            )
          }
        />
        <FloatButton
          icon={<AiOutlineMessage />}
          onClick={() =>
            window.open("sms:919104106339&body=Hi", "_blank", "noreferrer")
          }
        />
        <FloatButton
          icon={<AiOutlineMail />}
          onClick={() =>
            window.open(
              "mailto:navkarpolymers.sales@gmail.com",
              "_blank",
              "noreferrer"
            )
          }
        />
        <FloatButton
          icon={<IoCallOutline />}
          onClick={() =>
            window.open("tel:+919104106339", "_blank", "noreferrer")
          }
        />
      </FloatButton.Group>
    </div>
  );
};

//whatsapp
//message
//email
//phone

export default FloatButtonComponent;
