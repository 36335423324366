import { ClientsDataSource } from "../data-source/ClientsDataSource";
import { HomeState } from "../types/HomeState";
import Marquee from "react-fast-marquee";
const dataSource = ClientsDataSource;
const Clients = (props: HomeState) => {
  return (
    <div className="clients">
      <Marquee
        autoFill={true}
        play={true}
        pauseOnHover={true}
        pauseOnClick={false}
        speed={50}
        loop={0}
      >
        {dataSource.block.children.map((item, index) => {
          return (
            <img
              src={item.children.img.children}
              style={{
                margin: props.ismobile ? "0 20px" : "0 40px",
                marginTop: props.ismobile ? 30 : 80,
                maxHeight: props.ismobile ? 60 : 150,
              }}
              alt={"client"}
              key={index}
              loading="lazy"
            />
          );
        })}
      </Marquee>
    </div>
  );
};

export default Clients;
