type BannerElement = {
  className: string;
};

type BannerTitle = {
  className: string;
  children: string;
  isimg?: string;
};

type BannerContent = {
  className: string;
  children: string;
};

type BannerButton = {
  className: string;
  children: string;
};

type BannerItem = {
  name: string;
  BannerElement: BannerElement;
  textWrapper: BannerElement;
  bg: BannerElement;
  title?: BannerTitle;
  content?: BannerContent;
  button?: BannerButton;
};

export type BannerDataSourceType = {
  wrapper: BannerElement;
  BannerAnim: {
    children: BannerItem[];
  };
};

export const BannerDataSource: BannerDataSourceType = {
  wrapper: { className: "homepagebanner" },
  BannerAnim: {
    children: [
      {
        name: "elem0",
        BannerElement: { className: "banner-user-elem" },
        textWrapper: { className: "homepagebanner-text-wrapper" },
        bg: { className: "bg bg0 lm2kqgg72bj-editor_css" },
      },
      {
        name: "elem2",
        BannerElement: { className: "banner-user-elem" },
        textWrapper: { className: "homepagebanner-text-wrapper" },
        bg: { className: "bg bg2 lm2komoilik-editor_css" },
        title: {
          className: "homepagebanner-title",
          children: `It is the worst of times but it is the best of times because we still have chance
             #GreenAgriculture`,
        },
      },
      {
        name: "elem3",
        BannerElement: { className: "banner-user-elem" },
        textWrapper: { className: "homepagebanner-text-wrapper" },
        bg: { className: "bg bg3 lm2komoilik-editor_css" },
        title: {
          className: "homepagebanner-title",
          children:
            'There is no such thing as "away" when we throw away it must go somewhere #RecycleResponsibly',
        },
      },
      // {
      //   name: "elem4",
      //   BannerElement: { className: "banner-user-elem" },
      //   textWrapper: { className: "homepagebanner-text-wrapper" },
      //   bg: { className: "bg bg4 lm2komoilik-editor_css" },
      //   title: {
      //     className: "homepagebanner-title",
      //     children:
      //       "Building homes, providing shelter, and fostering hope. #CommunityBuilders",
      //   },
      // },
      {
        name: "elem5",
        BannerElement: { className: "banner-user-elem" },
        textWrapper: { className: "homepagebanner-text-wrapper" },
        bg: { className: "bg bg5 lm2komoilik-editor_css" },
        title: {
          className: "homepagebanner-title2",
          children:
            "Fueling convenience and reliability one sip at a time. Explore endless possibilities with our masterbatch solutions. #PlasticsInEverydayLife",
        },
      },
    ],
  },
};
