import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import QueueAnim from "rc-queue-anim";
import { HomeState } from "../types/HomeState";
import { Col, Row } from "antd";
import TweenOne from "rc-tween-one";
import { WhoWeAreDataSource as dataSource } from "../data-source/AboutUs/WhoWeAreDataSource";

const WhoWeAre = (props: HomeState) => {
  const { ismobile } = props;

  const animType = {
    queue: ismobile ? "bottom" : "right",
    one: ismobile
      ? {
          scaleY: "+=0.3",
          opacity: 0,
          type: "from",
          ease: "easeOutQuad",
        }
      : {
          x: "-=30",
          opacity: 0,
          type: "from",
          ease: "easeOutQuad",
        },
  };
  return (
    <div {...dataSource.wrapper}>
      <OverPack
        {...dataSource.OverPack}
        //@ts-ignore
        component={Row}
      >
        <TweenOne
          key="img"
          //@ts-ignore
          animation={animType.one}
          resetStyle
          {...dataSource.imgWrapper}
          component={Col}
          componentProps={{
            md: dataSource.imgWrapper.md,
            xs: dataSource.imgWrapper.xs,
          }}
        >
          <span {...dataSource.img}>
            <img
              src={dataSource.img.children}
              width="100%"
              alt="img"
              loading="lazy"
            />
          </span>
        </TweenOne>
        <QueueAnim
          key="text"
          //@ts-ignore
          type={animType.queue}
          leaveReverse
          ease={["easeOutQuad", "easeInQuad"]}
          {...dataSource.textWrapper}
          //@ts-ignore
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          <h2 key="h1" {...dataSource.title}>
            {dataSource.title.children}
          </h2>
          <div key="p" {...dataSource.content}>
            {dataSource.content.children}
          </div>
        </QueueAnim>
      </OverPack>
    </div>
  );
};

export default WhoWeAre;
