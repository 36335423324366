import { Typography, Col, Row } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../../types/cardsType";
import { Image } from "antd";
import Title from "antd/es/typography/Title";

const { Text } = Typography;
const PPA = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  if (content.ismobile)
    return (
      <>
        <Title level={4}>PPA Additive Master Batch</Title>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                Polymer Processing Aid masterbatch (PPA) are high performance
                fluoroelastomers used in processing of polyolefin polymers. They
                function by continuously coating the die with a lubricating
                fluoropolymer which aids in processing polymers which are high
                susceptible to melt fracture due to high stresses they undergo
                at the die exit. They are used in all extrusion processes like
                LLDPE blown film, HDPE Pipes, PP Sheets and foams and HDPE Blow
                molding. They are highly desirable during processing hexane,
                octane and metallocene – LLDPE which have greater tendency to
                melt fracture.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
      </>
    );
  return (
    <>
      <Row
        key="content"
        className={content.className}
        gutter={content.gutter}
        justify="space-between"
        align="middle"
      >
        <Col className={text.className} span={18}>
          <Typography>
            <Paragraph>
              <Text strong>PPA Additive Master Batch: </Text>
              Polymer Processing Aid masterbatch (PPA) are high performance
              fluoroelastomers used in processing of polyolefin polymers. They
              function by continuously coating the die with a lubricating
              fluoropolymer which aids in processing polymers which are high
              susceptible to melt fracture due to high stresses they undergo at
              the die exit. They are used in all extrusion processes like LLDPE
              blown film, HDPE Pipes, PP Sheets and foams and HDPE Blow molding.
              They are highly desirable during processing hexane, octane and
              metallocene – LLDPE which have greater tendency to melt fracture.
            </Paragraph>
          </Typography>
        </Col>
        <Col className={img.className} span={6}>
          <Image
            src={content.img.children}
            alt="img"
            preview={false}
            width={"100%"}
          />
        </Col>
      </Row>
    </>
  );
};

export default PPA;
