import { Typography, List, Col, Row } from "antd";
import { ChildPageContentConfig } from "../../types/cardsType";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
const { Text } = Typography;
const ColourMasterBatch = (content: ChildPageContentConfig) => {
  const shades = [
    "Red",
    "Yellow",
    "Green",
    "Blue",
    "Orange",
    "Purple",
    "Pink",
    "Different Florescent colours",
    "Magenta",
    "Olive green",
    "Grey",
  ];
  const applications = [
    "Laminated Tarpaulin",
    "Non-Woven Fabrics",
    "Mulch Film",
    "Blown Film",
    "Wovensacks",
    "Moulding Applications",
    "Multilayer Packaging",
    "Mono/Multi Filament Yarns",
  ];
  const { text, img } = content;
  if (content.ismobile)
    return (
      <>
        <Title level={4}>Colour Masterbatch</Title>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="100%"
            alt="img"
            loading="lazy"
          />
        </Col>

        <Row key="content" style={{ paddingBlockStart: "20px" }}>
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                With our state-of-the-art technology and extensive color
                expertise, we have the capabilities to formulate custom color
                masterbatch solutions tailored to your precise specifications.
                From subtle nuances to bold statements, our wide range of
                pigments and additives allows us to achieve the perfect color
                match for your products. Whether you're in need of standard
                colors, specialty effects, or functional additives, we've got
                you covered.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
        <Row
          className={content.className}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Row>
            <Col>
              <List
                header={<Text strong>Shades for Colour Masterbatch:</Text>}
                dataSource={shades}
                renderItem={(item) => <List.Item>{"> " + item}</List.Item>}
                grid={{ column: 2 }}
              />
            </Col>
            <Col>
              <List
                header={<Text strong>Applications:</Text>}
                dataSource={applications}
                renderItem={(item) => (
                  <List.Item>
                    {"> "} <Text>{item}</Text>
                  </List.Item>
                )}
                grid={{ column: 2 }}
              />
            </Col>
          </Row>
        </Row>
      </>
    );
  return (
    <>
      <Row key="content" style={{ paddingBlockStart: "20px" }}>
        <Col className={text.className}>
          <Typography>
            <Paragraph>
              <Text strong>Colour Masterbatch: </Text> With our state-of-the-art
              technology and extensive color expertise, we have the capabilities
              to formulate custom color masterbatch solutions tailored to your
              precise specifications. From subtle nuances to bold statements,
              our wide range of pigments and additives allows us to achieve the
              perfect color match for your products. Whether you're in need of
              standard colors, specialty effects, or functional additives, we've
              got you covered.
            </Paragraph>
          </Typography>
        </Col>
      </Row>
      <Row
        className={content.className}
        gutter={content.gutter}
        justify="space-between"
        align="middle"
      >
        <Col span={12}>
          <Row>
            <Col>
              <List
                header={<Text strong>Shades for Colour Masterbatch:</Text>}
                dataSource={shades}
                renderItem={(item) => <List.Item>{"> " + item}</List.Item>}
                grid={{ column: 2 }}
              />
            </Col>
            <Col>
              <List
                header={<Text strong>Applications:</Text>}
                dataSource={applications}
                renderItem={(item) => (
                  <List.Item>
                    {"> "} <Text>{item}</Text>
                  </List.Item>
                )}
                grid={{ column: 2 }}
              />
            </Col>
          </Row>
        </Col>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="100%"
            alt="img"
            loading="lazy"
          />
        </Col>
      </Row>
    </>
  );
};

export default ColourMasterBatch;
