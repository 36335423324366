import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import QueueAnim from "rc-queue-anim";
import { HomeState } from "../types/HomeState";
import { Col, Row } from "antd";
import TweenOne from "rc-tween-one";
import { WhatWeDoDataSource as dataSource } from "../data-source/AboutUs/WhatWeDoDataSource";
import { getChildrenToRender } from "./utils";

const WhatWeDo = (props: HomeState) => {
  const { ismobile } = props;

  const getBlockChildren = (data: any[]) =>
    data.map(($item) => {
      const { ...item } = $item;
      const { title, img, content } = item;
      ["title", "img", "content"].forEach((key) => delete item[key]);
      return (
        <li key={item.name} {...item}>
          <span {...img}>
            <img src={img.children} width="100%" alt="img" loading="lazy" />
          </span>
          <h2 {...title}>{title.children}</h2>
          <div {...content}>{content.children}</div>
        </li>
      );
    });

  const ulChildren = getBlockChildren(dataSource.block.children);
  const queue = ismobile ? "bottom" : "left";
  const imgAnim = ismobile
    ? {
        y: 30,
        opacity: 0,
        delay: 600,
        type: "from",
        ease: "easeOutQuad",
      }
    : {
        x: 30,
        opacity: 0,
        type: "from",
        ease: "easeOutQuad",
      };
  return (
    <div {...dataSource.wrapper}>
      <OverPack
        {...dataSource.OverPack}
        //@ts-ignore
        component={Row}
      >
        <QueueAnim
          key="text"
          type={queue}
          leaveReverse
          ease={["easeOutQuad", "easeInQuad"]}
          {...dataSource.textWrapper}
          //@ts-ignore
          component={Col}
        >
          <div key="title" {...dataSource.titleWrapper}>
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
          </div>
          <QueueAnim
            component="ul"
            key="ul"
            type={queue}
            ease="easeOutQuad"
            {...dataSource.block}
          >
            {ulChildren}
          </QueueAnim>
        </QueueAnim>
        {!props.ismobile && (
          <TweenOne
            key="img"
            //@ts-ignore
            animation={imgAnim}
            resetStyle
            {...dataSource.img}
            component={Col}
          >
            <img
              src={dataSource.img.children}
              width="100%"
              alt="img"
              loading="lazy"
            />
          </TweenOne>
        )}
      </OverPack>
    </div>
  );
};

export default WhatWeDo;
