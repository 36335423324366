import { Col, Row, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../types/cardsType";

const Automotive = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  if (content.ismobile)
    return (
      <>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                Plastic masterbatch plays a pivotal role in the automotive
                industry, where its applications are diverse and crucial for
                enhancing both the aesthetic and functional aspects of vehicles.
                In the realm of automotive manufacturing, plastic materials have
                become increasingly popular due to their lightweight nature,
                which helps improve fuel efficiency, reduce emissions, and
                enhance overall vehicle performance. Plastic masterbatch is
                instrumental in meeting the specific requirements of automotive
                components, making them not only visually appealing but also
                functional and durable.
              </Paragraph>
              <Paragraph style={{ textAlign: "justify" }}>
                One of the primary uses of plastic masterbatch in the automotive
                sector is for achieving consistent color and appearance across
                various vehicle parts. Car interiors, such as dashboards, door
                panels, and steering wheels, often require specific color
                schemes and textures to create a visually appealing and
                comfortable environment for drivers and passengers. Masterbatch
                allows manufacturers to precisely control the color and finish
                of these components, ensuring they meet the design and branding
                specifications of the automaker.
              </Paragraph>
              <Paragraph style={{ textAlign: "justify" }}>
                Beyond aesthetics, plastic masterbatch is employed to improve
                the performance and safety features of automotive parts. For
                instance, additives in masterbatch can enhance the flame
                retardancy of plastics used in the interior of vehicles,
                reducing the risk of fire and improving passenger safety.
                Additionally, masterbatch formulations can increase the impact
                resistance of plastic components like bumpers and exterior trim,
                making them more durable and capable of withstanding minor
                collisions and everyday wear and tear.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
      </>
    );
  return (
    <Row key="content" className={content.className} gutter={content.gutter}>
      <Col className={text.className} xs={text.xs} md={text.md}>
        <Typography>
          <Paragraph style={{ textAlign: "justify" }}>
            Plastic masterbatch plays a pivotal role in the automotive industry,
            where its applications are diverse and crucial for enhancing both
            the aesthetic and functional aspects of vehicles. In the realm of
            automotive manufacturing, plastic materials have become increasingly
            popular due to their lightweight nature, which helps improve fuel
            efficiency, reduce emissions, and enhance overall vehicle
            performance. Plastic masterbatch is instrumental in meeting the
            specific requirements of automotive components, making them not only
            visually appealing but also functional and durable.
          </Paragraph>
          <Paragraph style={{ textAlign: "justify" }}>
            One of the primary uses of plastic masterbatch in the automotive
            sector is for achieving consistent color and appearance across
            various vehicle parts. Car interiors, such as dashboards, door
            panels, and steering wheels, often require specific color schemes
            and textures to create a visually appealing and comfortable
            environment for drivers and passengers. Masterbatch allows
            manufacturers to precisely control the color and finish of these
            components, ensuring they meet the design and branding
            specifications of the automaker.
          </Paragraph>
          <Paragraph style={{ textAlign: "justify" }}>
            Beyond aesthetics, plastic masterbatch is employed to improve the
            performance and safety features of automotive parts. For instance,
            additives in masterbatch can enhance the flame retardancy of
            plastics used in the interior of vehicles, reducing the risk of fire
            and improving passenger safety. Additionally, masterbatch
            formulations can increase the impact resistance of plastic
            components like bumpers and exterior trim, making them more durable
            and capable of withstanding minor collisions and everyday wear and
            tear.
          </Paragraph>
        </Typography>
      </Col>
      <Col className={img.className} xs={img.xs} md={img.md}>
        <img src={content.img.children} width="100%" alt="img" loading="lazy" />
      </Col>
    </Row>
  );
};

export default Automotive;
