export const WhoWeAreDataSource = {
  wrapper: { className: "whoweare-wrapper content1-wrapper" },
  OverPack: { className: "whoweare content1", playScale: 0.3 },
  imgWrapper: { className: "content1-img", md: 10, xs: 24 },
  img: {
    children: "/images/aboutus/who-we-are.webp",
  },
  textWrapper: { className: "content1-text", md: 14, xs: 24 },
  title: { className: "content1-title", children: "Who We Are?" },
  content: {
    className: "content1-content",
    children:
      "Founded in 1990, we bring a wealth of experience and expertise to the table. Our team is comprised of industry veterans, engineers, and creative minds who share a passion for excellence. We thrive on challenges and approach each project with a dedication to delivering customized solutions that cater to the unique needs of our clients.",
  },
};
