export const VisionMissionDataSource = {
  wrapper: { className: "about-us-wrapper visionmission-wrapper" },
  page: { className: "about-us visionmission" },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: "visionmission-title-wrapper",
    children: [
      {
        name: "title",
        className: "visionmission-title-h1",
        children: "The Heartbeat of Navkar Polymers",
      },
      {
        name: "content",
        className: "visionmission-title-content",
        children: "Unveiling Our Vision, Mission, and Values",
      },
    ],
  },
  childWrapper: {
    className: "visionmission-button-wrapper",
    children: [],
  },
  Carousel: {
    dots: false,
    className: "visionmission-carousel",
    wrapper: { className: "visionmission-block-wrapper" },
    children: {
      className: "visionmission-block",
      titleWrapper: {
        className: "visionmission-carousel-title-wrapper",
        title: { className: "visionmission-carousel-title" },
      },
      children: [
        {
          name: "block0",
          className: "visionmission-block-row",
          gutter: 40,
          title: {
            className: "visionmission-carousel-title-block",
            children: "平台自主训练流程",
          },
          children: [
            {
              className: "visionmission-block-col",
              md: 8,
              xs: 24,
              name: "child0",
              arrow: {
                className: "visionmission-block-arrow",
                children:
                  "https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg",
              },
              children: {
                className: "visionmission-block-child",
                children: [
                  {
                    name: "image",
                    className: "visionmission-block-image",
                    children: "/images/aboutus/vision.webp",
                  },
                  {
                    name: "title",
                    className: "visionmission-block-title",
                    children: "Vision",
                  },
                  {
                    name: "content",
                    className: "visionmission-block-content",
                    children:
                      "Pioneering plastic innovation for a global tomorrow that thrives on sustainability.",
                  },
                ],
              },
            },
            {
              className: "visionmission-block-col",
              md: 8,
              xs: 24,
              name: "child1",
              arrow: {
                className: "visionmission-block-arrow",
                children:
                  "https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg",
              },
              children: {
                className: "visionmission-block-child",
                children: [
                  {
                    name: "image",
                    className: "visionmission-block-image",
                    children: "/images/aboutus/mission.webp",
                  },
                  {
                    name: "title",
                    className: "visionmission-block-title",
                    children: "Mission",
                  },
                  {
                    name: "content",
                    className: "visionmission-block-content",
                    children:
                      "Striving for excellence in plastic masterbatch solutions, our mission is to enhance material performance while minimizing environmental impact.",
                  },
                ],
              },
            },
            {
              className: "visionmission-block-col",
              md: 8,
              xs: 24,
              name: "child2",
              arrow: {
                className: "visionmission-block-arrow",
                children:
                  "https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg",
              },
              children: {
                className: "visionmission-block-child",
                children: [
                  {
                    name: "image",
                    className: "visionmission-block-image",
                    children: "/images/aboutus/values.webp",
                  },
                  {
                    name: "title",
                    className: "visionmission-block-title",
                    children: "Values",
                  },
                  {
                    name: "content",
                    className: "visionmission-block-content",
                    children:
                      "Integrity, teamwork, and a relentless pursuit of quality guide our commitment to responsible manufacturing and customer satisfaction.",
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};
