import { Typography, Col, Row, List } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../../types/cardsType";
import Title from "antd/es/typography/Title";

const { Text } = Typography;
const applications = [
  "Food Grade",
  "Non Food Grade",
  "Anti Sulphur (Used for agriculture films)",
];
const UV = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  if (content.ismobile)
    return (
      <>
        <Title level={4}>UV Additive Master Batch</Title>

        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="100%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content"
          className={content.className}
          gutter={content.gutter}
        ></Row>
        <Row key="content" style={{ paddingBlockStart: "20px" }}>
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                Exposure to sunlight and UV rays can have adverse effects on the
                life of plastic products. UV radiation can break down the
                chemical bonds in a polymer. This process is called
                photodegradation. It ultimately causes cracking, chalking, color
                changes and the loss of physical properties. To counteract the
                damaging effect of UV light, UV stabilizers are used to solve
                the degradation problems associated with exposure to sunlight.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
        <Row
          className={content.className}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Row>
            <Col>
              <List
                header={<Text strong>Applications:</Text>}
                dataSource={applications}
                renderItem={(item) => (
                  <List.Item>
                    {"> "} <Text>{item}</Text>
                  </List.Item>
                )}
                grid={{ column: 2 }}
              />
            </Col>
            <Col>
              <List
                header={
                  <Text strong>We manufacture 3 types of UV Masterbatch:</Text>
                }
                dataSource={applications}
                renderItem={(item) => <List.Item>{"> " + item}</List.Item>}
                grid={{ column: 1, gutter: 2 }}
              />
            </Col>
          </Row>
        </Row>
      </>
    );
  return (
    <>
      <Row>
        <Col span={16}>
          <Row key="content" style={{ paddingBlockStart: "20px" }}>
            <Col className={text.className}>
              <Typography>
                <Paragraph>
                  <Text strong>UV Additive Master Batch: </Text> Exposure to
                  sunlight and UV rays can have adverse effects on the life of
                  plastic products. UV radiation can break down the chemical
                  bonds in a polymer. This process is called photodegradation.
                  It ultimately causes cracking, chalking, color changes and the
                  loss of physical properties. To counteract the damaging effect
                  of UV light, UV stabilizers are used to solve the degradation
                  problems associated with exposure to sunlight.
                </Paragraph>
              </Typography>
            </Col>
          </Row>

          <Col>
            <List
              header={
                <Text strong>We manufacture 3 types of UV Masterbatch:</Text>
              }
              dataSource={applications}
              renderItem={(item) => <List.Item>{"> " + item}</List.Item>}
              grid={{ column: 2 }}
            />
          </Col>
        </Col>

        <Col className={img.className} span={8}>
          <img
            src={content.img.children}
            width="100%"
            alt="img"
            loading="lazy"
          />
        </Col>
      </Row>
    </>
  );
};

export default UV;
