import { Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";
import TweenOne, { TweenOneGroup } from "rc-tween-one";
import BannerAnim, { Element } from "rc-banner-anim";
import "rc-banner-anim/assets/index.css";
import {
  BannerDataSource,
  BannerDataSourceType,
} from "../data-source/BannerDataSource";
import { HomeState } from "../types/HomeState";

const { BgElement } = Element;

const HomePageBanner = (props: HomeState) => {
  const dataSource: BannerDataSourceType = BannerDataSource;

  const getChildrenToRenderIn = (children: any[]) => {
    return children.map((item: any, i: number) => {
      const elem = item.BannerElement;
      const elemClassName = elem.className;
      delete elem.className;
      const { bg, textWrapper, title, content, button } = item;
      return (
        <Element key={i.toString()} {...elem} prefixCls={elemClassName}>
          <link
            rel="preload"
            as="image"
            href={"/public/images/home/banner 1.webp"}
          />
          <BgElement key="bg" {...bg} />
          <QueueAnim
            type={["bottom", "top"]}
            delay={200}
            key="text"
            {...textWrapper}
          >
            <div className="homepagebanner-text-wrapper-block">
              {title && title.children && (
                <div key="logo" {...title}>
                  {title.children}
                </div>
              )}
              {content && content.children && (
                <div key="content" {...content}>
                  {content.children}
                </div>
              )}
            </div>
            {button && button.children && (
              <Button ghost key="button" {...button}>
                {button.children}
              </Button>
            )}
          </QueueAnim>
        </Element>
      );
    });
  };
  return (
    <div {...dataSource.wrapper}>
      <TweenOneGroup
        key="bannerGroup"
        enter={{ opacity: 0, type: "from" }}
        leave={{ opacity: 0 }}
        component=""
      >
        <div className="homepagebanner-wrapper" key="wrapper">
          <BannerAnim
            key="BannerAnim"
            type="across"
            {...dataSource.BannerAnim}
            autoPlay
            autoPlaySpeed={5000}
            dragPlay={!!props.ismobile}
          >
            {getChildrenToRenderIn(dataSource.BannerAnim.children)}
          </BannerAnim>
        </div>
      </TweenOneGroup>
      {!props.ismobile && (
        <TweenOne
          animation={{
            y: "-=20",
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="homepagebanner-icon"
          style={{ bottom: 40 }}
          key="icon"
        >
          <DownOutlined />
        </TweenOne>
      )}
    </div>
  );
};

export default HomePageBanner;
