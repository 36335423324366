import { Typography, Col, Row } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../types/cardsType";
import Title from "antd/es/typography/Title";
const { Text } = Typography;

const AdditiveMasterBatch = (content: ChildPageContentConfig) => {
  const { text, img } = content;

  if (content.ismobile)
    return (
      <>
        <Title level={4}>Additive Masterbatch</Title>
        <Row
          className={content.className}
          gutter={content.gutter}
          justify="center"
          align="middle"
        >
          <Col className={img.className} xs={img.xs} md={img.md}>
            <img
              src={content.img.children}
              width="100%"
              alt="img"
              loading="lazy"
            />
          </Col>
        </Row>
        <Row key="content" style={{ paddingBlockStart: "20px" }}>
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                Additive Master Batch is your go-to solution for enhancing the
                performance and versatility of your plastic products. Our
                specialized masterbatches are formulated with precision to
                incorporate a wide range of additives seamlessly into your
                manufacturing process. Whether you need to improve UV
                resistance, color consistency, flame retardancy, or other
                critical properties, AdditiveMasterBatch empowers you to achieve
                superior results efficiently and cost-effectively. Elevate your
                manufacturing capabilities with our innovative additive
                solutions and unlock new possibilities for your products.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
      </>
    );
  return (
    <>
      <Row key="content" style={{ paddingBlockStart: "20px" }}>
        <Col className={text.className}>
          <Typography>
            <Paragraph>
              <Text strong>Additive Masterbatch: </Text> Additive Master Batch
              is your go-to solution for enhancing the performance and
              versatility of your plastic products. Our specialized
              masterbatches are formulated with precision to incorporate a wide
              range of additives seamlessly into your manufacturing process.
              Whether you need to improve UV resistance, color consistency,
              flame retardancy, or other critical properties,
              AdditiveMasterBatch empowers you to achieve superior results
              efficiently and cost-effectively. Elevate your manufacturing
              capabilities with our innovative additive solutions and unlock new
              possibilities for your products.
            </Paragraph>
          </Typography>
        </Col>
      </Row>
      <Row
        className={content.className}
        gutter={content.gutter}
        justify="center"
        align="middle"
      >
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="100%"
            alt="img"
            loading="lazy"
          />
        </Col>
      </Row>
    </>
  );
};

export default AdditiveMasterBatch;
