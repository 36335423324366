import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import QueueAnim from "rc-queue-anim";
import { Key } from "react";
import { Row, Col } from "antd";
import { VisionMissionDataSource as dataSource } from "../data-source/AboutUs/VisionMissionDataSource";
import { getChildrenToRender } from "./utils";
import { HomeState } from "../types/HomeState";

const VisionMission = (props: HomeState) => {
  const getChildrenToRenderFunc = (dataSource: any) => {
    const { Carousel } = dataSource;
    const { children: carouselChild } = Carousel;
    const {
      titleWrapper,
      children: childWrapper,
      ...childrenProps
    } = carouselChild;

    const {
      barWrapper,
      title: titleChild,
      ...titleWrapperProps
    } = titleWrapper;
    const titleToRender: JSX.Element[] = [];

    const childrenToRender = childWrapper.map(
      (item: { [x: string]: any; title: any; children: any }, ii: number) => {
        const { title, children: childRow, ...rowProps } = item;
        if (childWrapper.length > 1) {
          titleToRender.push(
            <div {...title} key={ii.toString()}>
              {title.children}
            </div>
          );
        }
        const childrenItem = childRow.map(
          (
            item: { [x: string]: any; children: any },
            i: { toString: () => Key | null | undefined }
          ) => {
            const { children: colChild, ...colProps } = item;
            const { ...childProps } = colChild;
            return (
              <Col {...colProps} key={i.toString()}>
                <div {...childProps}>
                  {colChild.children.map(getChildrenToRender)}
                </div>
              </Col>
            );
          }
        );

        return (
          <div key={ii.toString()}>
            <QueueAnim
              //@ts-ignore
              component={Row}
              type="bottom"
              componentProps={{ type: "flex" }}
              {...rowProps}
            >
              {childrenItem}
            </QueueAnim>
          </div>
        );
      }
    );

    return (
      <QueueAnim key="queue" type="bottom" {...childrenProps}>
        {childWrapper.length > 1 && (
          <div {...titleWrapperProps} key="title">
            <div {...titleChild}>{titleToRender}</div>
          </div>
        )}
        {childrenToRender}
      </QueueAnim>
    );
  };

  const { titleWrapper } = dataSource;
  return (
    <div {...dataSource.wrapper}>
      <div {...dataSource.page}>
        <div {...dataSource.titleWrapper}>
          {titleWrapper.children.map(getChildrenToRender)}
        </div>
        <OverPack {...dataSource.OverPack}>
          {getChildrenToRenderFunc(dataSource)}
        </OverPack>
      </div>
    </div>
  );
};

export default VisionMission;
