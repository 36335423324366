import { Typography, Col, Row } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../../types/cardsType";
import Title from "antd/es/typography/Title";

const { Text } = Typography;
const AntiFog = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  if (content.ismobile)
    return (
      <>
        <Title level={4}>Anti Fog Additive Master Batch</Title>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                An anti-fog coating composition comprises a silicone polymer or
                oligomer, a water dispersible polyurethane polymer or oligomer,
                and water. A process for forming an anti-fog film comprises
                applying the anti-fog coating composition to a substrate and
                coalescing the silicone and polyurethane compounds to form the
                film.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
      </>
    );
  return (
    <>
      <Row
        key="content"
        className={content.className}
        gutter={content.gutter}
        justify="space-between"
        align="middle"
      >
        <Col className={text.className} span={16}>
          <Typography>
            <Paragraph>
              <Text strong>Anti Fog Additive Master Batch: </Text>
              An anti-fog coating composition comprises a silicone polymer or
              oligomer, a water dispersible polyurethane polymer or oligomer,
              and water. A process for forming an anti-fog film comprises
              applying the anti-fog coating composition to a substrate and
              coalescing the silicone and polyurethane compounds to form the
              film.
            </Paragraph>
          </Typography>
        </Col>
        <Col className={img.className} span={8}>
          <img
            src={content.img.children}
            width="100%"
            alt="img"
            loading="lazy"
          />
        </Col>
      </Row>
    </>
  );
};

export default AntiFog;
