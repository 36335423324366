import { Typography, Row, Col, List } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../../types/cardsType";
import Title from "antd/lib/typography/Title";
const { Text } = Typography;

const MulchFilm = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  const data = [
    "Black",
    "Silver",
    "White",
    "UV (Sulfur and Non-sulfur)",
    "Anti Oxidant",
    "PPA",
    "Optical Brightner",
  ];
  if (content.ismobile)
    return (
      <>
        <Title level={4}>Mulch Film</Title>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                Mulch films are films that help in the covering of the soil over
                which the plants are grown. These films are extremely useful in
                the effective growth of the plants since they offer a lot of
                protection to the plants. This is exactly the reason to why the
                use of these films is highly preferred.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
        <Row
          key="content"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
        >
          <List
            header={<Text strong>Suggested Masterbatch:</Text>}
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                {"> "} <Text>{item}</Text>
              </List.Item>
            )}
            grid={{ column: 2 }}
          />
        </Row>
      </>
    );
  return (
    <>
      <Row key="content" style={{ paddingBlockStart: "20px" }}>
        <Col className={text.className}>
          <Typography>
            <Paragraph style={{ textAlign: "justify" }}>
              <Text strong>Mulch Film: </Text> Mulch films are films that help
              in the covering of the soil over which the plants are grown. These
              films are extremely useful in the effective growth of the plants
              since they offer a lot of protection to the plants. This is
              exactly the reason to why the use of these films is highly
              preferred.
            </Paragraph>
          </Typography>
        </Col>
      </Row>
      <Row
        className={content.className}
        gutter={content.gutter}
        justify="space-between"
        align="middle"
      >
        <Col span={12}>
          <Row>
            <Col>
              <List
                header={<Text strong>Suggested Masterbatch:</Text>}
                // bordered
                dataSource={data}
                renderItem={(item) => (
                  <List.Item>
                    {"> "} <Text>{item}</Text>
                  </List.Item>
                )}
                grid={{ column: 1, gutter: 2 }}
              />
            </Col>
          </Row>
        </Col>
        <Col className={img.className}>
          <div style={{ height: "60%" }}>
            <img src={content.img.children} alt="img" loading="lazy" />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default MulchFilm;
