import { Typography, Col, Row } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../../types/cardsType";
import Title from "antd/es/typography/Title";

const { Text } = Typography;
const AntiMicrobials = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  if (content.ismobile)
    return (
      <>
        <Title level={4}>Anti Microbials Additive Master Batch</Title>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                Antimicrobial masterbatches are used to control the build up of
                bacteria and fungi on the surface of plastic products and
                textiles. They can be added to formulations in the same way as
                other ingredients, by use of masterbatches.
              </Paragraph>
              <Paragraph style={{ textAlign: "justify" }}>
                A high performance anti microbial masterbatch is designed to
                address the growing threat posed by bacteria to humans as they
                go about their daily chores. These tiny and almost invisible
                micro organism are all round us, often multiplying in damp
                places like household kitchens, bathrooms, toilets, clothes and
                hospitals beds.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
      </>
    );
  return (
    <>
      <Row
        key="content"
        className={content.className}
        gutter={content.gutter}
        justify="space-between"
        align="middle"
      >
        <Col className={text.className} span={16}>
          <Typography>
            <Paragraph>
              <Text strong>Anti Microbials Additive Master Batch: </Text>
              Antimicrobial masterbatches are used to control the build up of
              bacteria and fungi on the surface of plastic products and
              textiles. They can be added to formulations in the same way as
              other ingredients, by use of masterbatches.
            </Paragraph>
            <Paragraph>
              A high performance anti microbial masterbatch is designed to
              address the growing threat posed by bacteria to humans as they go
              about their daily chores. These tiny and almost invisible micro
              organism are all round us, often multiplying in damp places like
              household kitchens, bathrooms, toilets, clothes and hospitals
              beds.
            </Paragraph>
          </Typography>
        </Col>
        <Col className={img.className} span={8}>
          <img
            src={content.img.children}
            width="100%"
            alt="img"
            loading="lazy"
          />
        </Col>
      </Row>
    </>
  );
};

export default AntiMicrobials;
