import { Col, List, Row, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../types/cardsType";
import Title from "antd/es/typography/Title";
const { Text } = Typography;
const WhiteMasterBatch = (content: ChildPageContentConfig) => {
  const applications = [
    "Laminated Tarpaulin",
    "Non Woven fabrics",
    "Mono/Multi Layer Blown Film",
    "Rotational/Blow Moulding",
    "Injection Moulding",
    "Wovensacks",
  ];

  const { text, img } = content;
  if (content.ismobile)
    return (
      <>
        <Title level={4}>White Masterbatch</Title>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                White Masterbatch are used in a wide range of applications where
                high opacity and whitness required. Using our extensive
                knowledge and state-of-the-art production capabilities, we are
                able to produce high-quality MB containing different TiO2
                grades, sourced from top producers around the world. By
                combining the right TiO2 grade, carrier resin, stabilizers and
                process equipment, we provide the highest-quality MB for each
                application. White mb ranging upto 75% Tio2 as per requirements.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
        <Row
          key="content"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
        >
          <List
            header={<Text strong>Applications:</Text>}
            dataSource={applications}
            renderItem={(item) => (
              <List.Item>
                {"> "} <Text>{item}</Text>
              </List.Item>
            )}
            grid={{ column: 2 }}
          />
        </Row>
      </>
    );
  return (
    <>
      <Row
        key="whitemasterbatch"
        style={{ paddingBlockStart: "20px" }}
        gutter={content.gutter}
        justify="space-between"
        align="middle"
      >
        <Col className={text.className}>
          <Typography>
            <Paragraph>
              <Text strong>White Masterbatch: </Text> White Masterbatch are used
              in a wide range of applications where high opacity and whitness
              required. Using our extensive knowledge and state-of-the-art
              production capabilities, we are able to produce high-quality MB
              containing different TiO2 grades, sourced from top producers
              around the world. By combining the right TiO2 grade, carrier
              resin, stabilizers and process equipment, we provide the
              highest-quality MB for each application. White mb ranging upto 75%
              Tio2 as per requirements.
            </Paragraph>
          </Typography>
        </Col>
      </Row>
      <Row
        key="content"
        style={{ paddingBlockStart: "20px" }}
        gutter={content.gutter}
      >
        <Col className={text.className} span={12}>
          <List
            header={<Text strong>Applications:</Text>}
            dataSource={applications}
            renderItem={(item) => (
              <List.Item>
                {"> "} <Text>{item}</Text>
              </List.Item>
            )}
            grid={{ column: 2 }}
          />
        </Col>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
      </Row>
    </>
  );
};

export default WhiteMasterBatch;
