import { Col, Row, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../types/cardsType";

const Packing = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  if (content.ismobile)
    return (
      <>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                Plastic masterbatch plays a crucial role in the field of
                packaging, offering a wide range of benefits that contribute to
                the functionality, aesthetics, and sustainability of packaging
                materials. Masterbatch is essentially a concentrated blend of
                pigments, additives, or other chemicals that are added to
                plastic during the manufacturing process. Here's a closer look
                at the usage of plastic masterbatch in packaging:
              </Paragraph>
              <Paragraph style={{ textAlign: "justify" }}>
                Color Consistency: One of the primary applications of plastic
                masterbatch in packaging is achieving consistent and vibrant
                colors. Whether it's for food packaging, consumer goods, or
                industrial products, maintaining a specific color tone is
                essential for branding and marketing. Masterbatch allows
                manufacturers to precisely control the color of plastic
                packaging, ensuring that it remains consistent throughout
                production batches. This consistency enhances the visual appeal
                of products on store shelves and reinforces brand identity.
              </Paragraph>
              <Paragraph style={{ textAlign: "justify" }}>
                UV Protection: UV radiation can degrade and discolor plastic
                packaging over time, reducing its shelf life and aesthetic
                appeal. Plastic masterbatch additives, such as UV stabilizers,
                are used to protect packaging materials from the harmful effects
                of UV rays. This is especially crucial for outdoor or
                transparent packaging, as it extends the lifespan of the product
                and preserves its appearance.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
      </>
    );
  return (
    <Row key="content" className={content.className} gutter={content.gutter}>
      <Col className={text.className} xs={text.xs} md={text.md}>
        <Typography>
          <Paragraph style={{ textAlign: "justify" }}>
            Plastic masterbatch plays a crucial role in the field of packaging,
            offering a wide range of benefits that contribute to the
            functionality, aesthetics, and sustainability of packaging
            materials. Masterbatch is essentially a concentrated blend of
            pigments, additives, or other chemicals that are added to plastic
            during the manufacturing process. Here's a closer look at the usage
            of plastic masterbatch in packaging:
          </Paragraph>
          <Paragraph style={{ textAlign: "justify" }}>
            Color Consistency: One of the primary applications of plastic
            masterbatch in packaging is achieving consistent and vibrant colors.
            Whether it's for food packaging, consumer goods, or industrial
            products, maintaining a specific color tone is essential for
            branding and marketing. Masterbatch allows manufacturers to
            precisely control the color of plastic packaging, ensuring that it
            remains consistent throughout production batches. This consistency
            enhances the visual appeal of products on store shelves and
            reinforces brand identity.
          </Paragraph>
          <Paragraph style={{ textAlign: "justify" }}>
            UV Protection: UV radiation can degrade and discolor plastic
            packaging over time, reducing its shelf life and aesthetic appeal.
            Plastic masterbatch additives, such as UV stabilizers, are used to
            protect packaging materials from the harmful effects of UV rays.
            This is especially crucial for outdoor or transparent packaging, as
            it extends the lifespan of the product and preserves its appearance.
          </Paragraph>
        </Typography>
      </Col>
      <Col className={img.className} xs={img.xs} md={img.md}>
        <img src={content.img.children} width="100%" alt="img" loading="lazy" />
      </Col>
    </Row>
  );
};

export default Packing;
