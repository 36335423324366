import { Typography, List, Row, Col } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { ChildPageContentConfig } from "../../../types/cardsType";
import Title from "antd/es/typography/Title";
const { Text } = Typography;

const DripIrrigation = (content: ChildPageContentConfig) => {
  const { text, img } = content;
  const data = ["Black", "Blue", "Orange", "Green", "UV", "PPA"];
  if (content.ismobile)
    return (
      <>
        <Title level={4}>Drip Irrigation</Title>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="90%"
            alt="img"
            loading="lazy"
          />
        </Col>
        <Row
          key="content2"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
          justify="space-between"
          align="middle"
        >
          <Col className={text.className}>
            <Typography>
              <Paragraph style={{ textAlign: "justify" }}>
                Drip irrigation or trickle irrigation is a type of
                micro-irrigation system that has the potential to save water and
                nutrients by allowing water to drip slowly to the roots of
                plants, either from above the soil surface or buried below the
                surface.
              </Paragraph>
            </Typography>
          </Col>
        </Row>
        <Row
          key="content"
          style={{ paddingBlockStart: "20px" }}
          gutter={content.gutter}
        >
          <List
            header={<Text strong>Suggested Masterbatch:</Text>}
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                {"> "} <Text>{item}</Text>
              </List.Item>
            )}
            grid={{ column: 2 }}
          />
        </Row>
      </>
    );
  return (
    <>
      <Row key="content" style={{ paddingBlockStart: "20px" }}>
        <Col className={text.className}>
          <Typography>
            <Paragraph style={{ textAlign: "justify" }}>
              <Text strong>Drip Irrigation: </Text> Drip irrigation or trickle
              irrigation is a type of micro-irrigation system that has the
              potential to save water and nutrients by allowing water to drip
              slowly to the roots of plants, either from above the soil surface
              or buried below the surface.
            </Paragraph>
          </Typography>
        </Col>
      </Row>
      <Row
        className={content.className}
        gutter={content.gutter}
        justify="space-between"
        align="middle"
      >
        <Col span={12}>
          <Row>
            <Col>
              <List
                header={<Text strong>Suggested Masterbatch:</Text>}
                // bordered
                dataSource={data}
                renderItem={(item) => (
                  <List.Item>
                    {"> "} <Text>{item}</Text>
                  </List.Item>
                )}
                grid={{ column: 1, gutter: 2 }}
              />
            </Col>
          </Row>
        </Col>
        <Col className={img.className} xs={img.xs} md={img.md}>
          <img
            src={content.img.children}
            width="100%"
            alt="img"
            loading="lazy"
          />
        </Col>
      </Row>
    </>
  );
};

export default DripIrrigation;
