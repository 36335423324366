export const CompanyLogoBannerDataSource = {
  wrapper: { className: "banner0" },
  textWrapper: { className: "banner0-text-wrapper" },
  title: {
    className: "banner0-title",
    children: "/images/logo.webp",
  },
  content: {
    className: "banner0-content",
    children: "XYZ brand tagline",
  },
  button: { className: "banner0-button", children: "Learn More" },
};
